import { DeviceAlert } from "../deviceAlert";

export type RawDeviceServicePoint = {
  readonly id: string;
  readonly code: string;
  readonly currencies: string[];
  readonly ip: string;
  readonly active: boolean;
  readonly manufacturer: string;
  readonly model: string;
  readonly name: string;
  readonly port: number;
  readonly type: string;
  readonly manufacturerNoteCapacity: number;
  readonly realNoteCapacity: number;
  readonly prosegurNoteCapacity: number;
  readonly manufacturerCoinCapacity: number;
  readonly realCoinCapacity: number;
  readonly prosegurCoinCapacity: number;
  deviceAlerts: DeviceAlert[];
};

export const iniRawDeviceServicePoint: RawDeviceServicePoint = {
  id: "",
  name: "",
  code: "",
  ip: "",
  port: 0,
  type: "",
  model: "",
  manufacturer: "",
  currencies: [],
  active: true,
  manufacturerNoteCapacity: 0,
  realNoteCapacity: 0,
  prosegurNoteCapacity: 0,
  manufacturerCoinCapacity: 0,
  realCoinCapacity: 0,
  prosegurCoinCapacity: 0,
  deviceAlerts: [],
};
