
import { PDropDownContainer } from "@/common/components";
import { Component, Vue } from "vue-property-decorator";
import UsersAllDropDown from "./UsersAllDropDown.vue";
import { PRow } from "@/common/components/look/PRow";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  name: "users-all",
  components: {
    PRow,
    PIcon,
    PDropDownContainer,
    UsersAllDropDown,
  },
})
export class UsersAll extends Vue {
  filter(type: string): void {
    this.$emit("filter", "type", type);
  }

  reset(): void {
    this.$emit("reset", "type");
  }

  filterUserByType(): void {
    //todo ver funcionalidad
  }
}
export default UsersAll;
