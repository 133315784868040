
import { Component, Vue, Prop } from "vue-property-decorator";
import { i18n } from "@/i18n";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { EventBus } from "@/event-bus";
import { PModal, PButton } from "@/common/components";

@Component({
  name: "condition",
  i18n,
  components: {
    PIcon,
    PModal,
    PButton,
  },
})
export class Condition extends Vue {
  @Prop({ required: true, default: "" }) conditionKey!: string;
  @Prop({ required: true, default: "" }) conditionValue!: string;
  @Prop({ required: true, default: "" }) conditionId!: string;

  showDeleteConditionModal = false;
  deleteConditionModalTitle = this.$i18n.t("groups.permissions.delete_condition_confirmation");

  deleteCondition(): void {
    EventBus.$emit("delete-automatic-condition", this.conditionId);
  }

  openDeleteConditionModal(): void {
    this.showDeleteConditionModal = true;
  }

  closeDeleteConditionModal(): void {
    this.showDeleteConditionModal = false;
  }
}
export default Condition;
