import { RawCurrency } from "./currency.type";
import { Currency } from "./currency";

export const mockRawCurrency = (): RawCurrency[] => [
  {
    isoCode: "USD",
    description: "US Dollar",
  },
  {
    isoCode: "PEN",
    description: "Nuevo Sol",
  },
  {
    isoCode: "EUR",
    description: "Euro",
  },
  {
    isoCode: "JPY",
    description: "Yen",
  },
];

export const mockCurrency = (data: RawCurrency[] = mockRawCurrency()): Currency[] =>
  data.map((item) => new Currency(item));
