
import { Component, Vue } from "vue-property-decorator";
import MachineNewModal from "../machine-new-modal/MachineNewModal.vue";
import { PRow } from "@/common/components/look/PRow";
import { PCol } from "@/common/components/look/PCol";

@Component({
  name: "machine-header",
  components: {
    PRow,
    PCol,
    MachineNewModal,
  },
})
export class MachineHeader extends Vue {
  search(): void {
    this.$emit("action");
  }
}
export default MachineHeader;
