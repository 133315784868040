import { render, staticRenderFns } from "./PFormSelect.vue?vue&type=template&id=31d633d4&scoped=true&"
import script from "./PFormSelect.vue?vue&type=script&lang=ts&"
export * from "./PFormSelect.vue?vue&type=script&lang=ts&"
import style0 from "./PFormSelect.vue?vue&type=style&index=0&id=31d633d4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d633d4",
  null
  
)

export default component.exports