
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "breadcrum",
})
export class Breadcrum extends Vue {
  @Prop({ required: true }) title!: string[];
  @Prop({ required: true }) path!: string[];

  get titles(): string[] {
    return this.title.slice(0, -1);
  }

  navigate(index: number): void {
    console.log(this.path[index]);
    this.$router.push(this.path[index]);
  }
}
export default Breadcrum;
