
import { Center, Entity, ServicePoint } from "@/entities";
import { i18n } from "@/i18n";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PButton, PModal } from "@/common/components";
import DeviceFormServicePoint from "@/components/devices/device-form/DeviceForm.vue";
import { DeviceServicePoint } from "@/entities/deviceservicepoint";

@Component({
  name: "device-modal-service-point",
  i18n,
  components: {
    PModal,
    PIcon,
    PButton,
    DeviceFormServicePoint,
  },
})
export class DeviceModal extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.showModal = this.value;
  }
  @Prop({ required: true }) center!: Center;
  @Prop({ required: true }) entity!: Entity;
  @Prop({ required: true }) servicePoint!: ServicePoint;
  @Prop({ required: true }) device!: DeviceServicePoint;
  @Prop({ required: false, default: true }) isModify!: boolean;
  @Prop({ required: false, default: "button.btn_save" }) textActionName!: string;
  showModal = false;

  closeModal(): void {
    this.showModal = false;
    this.$emit("input", false);
  }

  get titleModal(): string {
    return this.isModify
      ? this.$t("spPage.modal-device.modal_edit_title") + this.servicePoint.name
      : (this.$t("spPage.modal-device.modal_create_title") as string);
  }

  saveDevice(servicePoint: ServicePoint, device: DeviceServicePoint, update: boolean): void {
    if (update) {
      this.$services.servicepoint.updateDeviceServicePoint(device).then(() => {
        Vue.swal({
          icon: "success",
          title: this.$i18n.t("spPage.modal-device.success"),
          showConfirmButton: false,
          timer: 2000,
        });
        this.$emit("refresh");
        this.closeModal();
      });
    } else {
      this.$services.servicepoint.createServicePointAndDevice(servicePoint, device).then(() => {
        Vue.swal({
          icon: "success",
          title: this.$i18n.t("spPage.modal-device.success_create"),
          showConfirmButton: false,
          timer: 2000,
        });
        this.$emit("refresh");
        this.closeModal();
      });
    }
  }
}

export default DeviceModal;
