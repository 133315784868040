import {
  iniRawCenter,
  iniRawDevice,
  iniRawEntity,
  iniRawServicePointType,
  RawCenter,
  RawDevice,
  RawEntity,
  RawLimit,
  RawServicePointType,
} from "@/entities";

export type RawServicePoint = {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly earlyValue: boolean;
  readonly aggregateId: string;
  readonly entity: RawEntity;
  readonly center: RawCenter;
  readonly device: RawDevice;
  readonly servicePointType: RawServicePointType;
  readonly currencyLimits: RawLimit[];
  readonly deviceCountByType: string;
  readonly active: boolean;
};

export const iniRawServicePoint: RawServicePoint = {
  id: "",
  name: "",
  code: "",
  earlyValue: false,
  aggregateId: "",
  entity: { ...iniRawEntity },
  center: { ...iniRawCenter },
  device: { ...iniRawDevice },
  servicePointType: { ...iniRawServicePointType },
  currencyLimits: [],
  deviceCountByType: "",
  active: true,
};
