import {
  AuthService,
  CenterServiceImpl,
  CountryServiceImpl,
  CurrencyServiceImpl,
  DeviceServiceImpl,
  EntityServiceImpl,
  GroupServiceImpl,
  MachineServiceImpl,
  ServicePointServiceImpl,
  SolutionHistoryServiceImpl,
  SolutionServiceImpl,
  SupportTransactionServiceImpl,
  UserGroupServiceImpl,
  UserServiceImpl,
} from "@/services";
import { IStore } from "@/store";
import Vue from "vue";
import { IProvider } from "./provider.types";

export const provider = (): IProvider => ({
  auth: new AuthService(),
  user: new UserServiceImpl(),
  country: new CountryServiceImpl(),
  entity: new EntityServiceImpl(),
  center: new CenterServiceImpl(),
  machine: new MachineServiceImpl(),
  group: new GroupServiceImpl(),
  supportTransaction: new SupportTransactionServiceImpl(),
  userGroup: new UserGroupServiceImpl(),
  solutionHistory: new SolutionHistoryServiceImpl(),
  currency: new CurrencyServiceImpl(),
  solution: new SolutionServiceImpl(),
  servicepoint: new ServicePointServiceImpl(),
  device: new DeviceServiceImpl(),
});

export const prepareServices = (store: IStore): void => {
  store.$services = provider();

  Vue.mixin({
    beforeCreate() {
      this.$services = store.$services;
    },
  });

  Vue.$axios.interceptors.request.use(async (config) => {
    config.headers!.Accept = "application/json";
    return config;
  });
};
