
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { mapGetters } from "vuex";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import PDropDownContainer from "../../look/PDropDownContainer/PDropDownContainer.vue";
import { User } from "@/entities";
import { i18n } from "@/i18n";

@Component({
  name: "sidebar",
  i18n,
  computed: {
    ...mapGetters(["getIsSignedIn"]),
  },
  components: {
    PIcon,
    PDropDownContainer,
  },
})
export default class Sidebar extends Vue {
  @Getter("getUrl") getUrl!: string;
  @Getter("getLoggedUser") loggedUser!: User;

  public activeLinkColor = "#FFD102";
  public inactiveLinkColor = "#4C4C4C";

  msgActive = true;
  shoeSubMenu = true;
  titleSubMenu = "";
  menu: any = [
    { name: "users", parent: "users" },
    { name: "machines", parent: "machines" },
    { name: "entities", parent: "entities" },
    { name: "panel-support", parent: "panel-support" },
  ];
  titleUsers = this.$i18n.t("users.title");
  titleMachines = this.$i18n.t("machine.title_page");
  titleEntities = this.$i18n.t("entity.title");
  titleSupport = this.$i18n.t("support_panel.title");

  get currentPath(): string {
    return new URL(this.getUrl).pathname;
  }

  get subMenuComp(): any[] {
    const subMenu = this.menu.find((me: any) => me.name === this.$route.name);
    if (subMenu) {
      this.titleSubMenu = this.$t("menu." + subMenu.parent) + "";
      return this.menu.filter((me: any) => me.parent === subMenu.parent);
    } else {
      return [];
    }
  }

  classMenuActive(nameRouter: string): string {
    const subMenu = this.menu.find((me: any) => me.name === this.$route.name);
    if (subMenu && subMenu.parent === nameRouter) {
      return "menuTopItemActive_Sidebar";
    } else {
      return "";
    }
  }

  classSubMenuActive(nameRouter: string) {
    if (this.$route.name === nameRouter) {
      return "subMenuItemActive_Sidebar";
    } else {
      return "";
    }
  }

  logout = (): void => {
    Vue.$auth.signoutRedirect();
  };
}
