import { RawServicePointType } from "@/entities/servicepointtype/servicepointtype.types";

export class ServicepointType {
  id: string;
  readonly name: string;
  readonly code: string;

  constructor(data: RawServicePointType) {
    this.id = data.aggregateId || data.id || "";
    this.name = data.name || "";
    this.code = data.code || "";
  }
}
