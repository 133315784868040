export type RawDelegation = {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly countryCode: string;
};

export const iniRawDelegation: RawDelegation = {
  id: "",
  code: "",
  name: "",
  countryCode: "",
};
