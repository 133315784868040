import { DeviceServicePoint, RawDeviceServicePoint } from "@/entities";

export const mockRawDevicesServicePoint = (): RawDeviceServicePoint[] => [
  {
    id: "c0528b78-4072-3a19-a599-440deb0e3f8a",
    code: "001344007070",
    name: "DEVICE TEST 02",
    type: "Recicladora",
    manufacturer: "CIMA",
    model: "SDM 504",
    ip: "10.28.75.199",
    port: 1288,
    active: true,
    currencies: ["PEN", "USD"],
    manufacturerNoteCapacity: 200,
    realNoteCapacity: 200,
    prosegurNoteCapacity: 200,
    manufacturerCoinCapacity: 200,
    realCoinCapacity: 200,
    prosegurCoinCapacity: 200,
    deviceAlerts: [],
  },
];

export const mockDevicesServicePoint = (
  data: RawDeviceServicePoint[] = mockRawDevicesServicePoint()
): DeviceServicePoint[] => data.map((item) => new DeviceServicePoint(item));
