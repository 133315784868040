import Vue from "vue";
import { Entity, RawEntity } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import {
  BACKEND_BASE_URL,
  BACKEND_BASE_URL_READS,
  EntityService,
  ResponseCreate,
  SearchEntitiesRequest,
} from "@/services";
import { RawPage } from "@/entities/pageable/pageable_types";
import { AxiosResponse } from "axios";

export class EntityServiceImpl implements EntityService {
  async fetchEntities(map: Record<string, unknown>): Promise<Pageable<Entity>> {
    const { data } = await Vue.$axios.post<RawPage<RawEntity>>(`${BACKEND_BASE_URL_READS}/api/v1/me/entities`, map);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawEntity) => new Entity(rawEntity)) ?? [],
    };
  }
  async fetchEntitiesV2(request: SearchEntitiesRequest): Promise<Pageable<Entity>> {
    const { data } = await Vue.$axios.post<RawPage<RawEntity>>(`${BACKEND_BASE_URL_READS}/api/v2/me/entities`, request);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawEntity) => new Entity(rawEntity)) ?? [],
    };
  }
  async updateEntity(entity: Entity): Promise<void> {
    await Vue.$axios.patch<void>(`${BACKEND_BASE_URL}/api/v1/entity/${entity.id}`, {
      name: entity.name,
      currencyLimits: entity.limits,
    });
  }

  async createEntity(entity: Entity): Promise<ResponseCreate> {
    const { data } = await Vue.$axios.post<ResponseCreate>(`${BACKEND_BASE_URL}/api/v1/entity`, {
      name: entity.name,
      currencyLimits: entity.limits,
      code: entity.code,
      countryCode: entity.countryCode,
    });
    return data;
  }
  async changeStatus(entity: Entity): Promise<void> {
    await Vue.$axios.patch<AxiosResponse>(`${BACKEND_BASE_URL}/api/v1/entity/${entity.id}`, {
      active: entity.active,
    });
  }
}
