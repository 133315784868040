
import { PDropDownContainer } from "@/common/components";
import { Component, Vue } from "vue-property-decorator";
import UserDropDownCountry from "./UserDropDownCountry.vue";
import { PRow } from "@/common/components/look/PRow";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  name: "user-country",
  components: {
    PRow,
    PIcon,
    PDropDownContainer,
    UserDropDownCountry,
  },
})
export default class UserCountry extends Vue {
  filterByCountryUser(country: string): void {
    this.$emit("filter", "country", country);
  }

  clearFilterByCountryUser(): void {
    this.$emit("reset", "country");
  }
}
