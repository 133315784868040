
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelectType, SelectOrderType } from "./pFormSelect.types";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
export class PFormSelectOrder extends Vue {
  @Prop({ required: true }) value!: SelectOrderType;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    if (this.value) {
      this.selectedItemValue = Object.assign(this.value);
    }
  }
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false, default: "Select an option" }) placeholder!: string;
  @Prop({ required: false, default: "button" }) variant!: string;

  selectedItemValue: SelectOrderType = { selected: "", orderDirection: "DESC" };
  pussOption = false;
  get variantCop(): string {
    let returnTemp: string = "inputSelect_PFormSelectOrder_" + this.variant;
    if (this.variant === "button" && this.selectedItemValue.selected != "") {
      returnTemp = returnTemp + " borderSelected_PFormSelectOrder";
    }
    return returnTemp;
  }
  get selectedText(): string {
    const optionFind = this.options.find((opt) => opt.value == this.selectedItemValue.selected);
    if (optionFind) {
      return optionFind.text;
    } else {
      return this.placeholder;
    }
  }
  selectItem(itemValue: any): void {
    //this.selectedItemValue = Object.assign(itemValue);
    this.selectedItemValue.selected = itemValue;
    if (this.selectedItemValue.orderDirection === "DESC") {
      this.selectedItemValue.orderDirection = "ASC";
    } else {
      this.selectedItemValue.orderDirection = "DESC";
    }
    this.$emit("input", this.selectedItemValue);
    this.$emit("change", this.selectedItemValue);
    this.pussOption = false;
  }
  giveMeClass(itemValue: any): string {
    if (this.selectedItemValue.selected == itemValue) {
      return "itemOption_PFormSelectOrder itemSelectSelected";
    } else {
      return "itemOption_PFormSelectOrder itemSelect";
    }
  }
}
export default PFormSelectOrder;
