import { AutomaticRules, Group, mockUserGroup, RawAutomaticRules, RawGroup, Rule, UserGroup } from "@/entities";
import Vue from "vue";
import Pageable from "@/entities/pageable/pageable_collection";
import { GroupService } from "./group.types";
import { BACKEND_BASE_URL, BACKEND_BASE_URL_READS, GroupDTO } from "../backend.types";
import { RawPage } from "@/entities/pageable/pageable_types";
import { AssignedServicePointParent } from "@/entities/assignedServicePoint/assignedServicePointParent";

export class GroupServiceImpl implements GroupService {
  async fetchGroups(map?: Record<string, unknown>): Promise<Pageable<Group>> {
    const defaultMap: Record<string, unknown> = {
      limit: 100,
      offset: 0,
      sortField: "lastUpdate",
      sortWay: "DESC",
      filters: [],
    };

    const paramMap = { ...defaultMap, ...map };

    const apiUrl = `${BACKEND_BASE_URL_READS}/api/v1/group`;

    if (Object.keys(paramMap).length > 0) {
      const { data } = await Vue.$axios.post<RawPage<RawGroup>>(apiUrl, paramMap);
      if (!data.results && data.projections) {
        data.results = data.projections;
      }
      const result: Pageable<Group> = {
        totalResult: data.totalResult,
        results: data.results.map((rawGroup) => new Group(rawGroup)) ?? [],
      };
      return result;
    } else {
      const { data } = await Vue.$axios.get<RawPage<RawGroup>>(apiUrl);
      if (!data.results && data.projections) {
        data.results = data.projections;
      }
      const result: Pageable<Group> = {
        totalResult: data.totalResult,
        results: data.results.map((rawGroup) => new Group(rawGroup)) ?? [],
      };
      return result;
    }
  }

  async fetchUsersGroup(): Promise<UserGroup> {
    //TODO invocar API
    const data = mockUserGroup()[0];
    return data;
  }

  async fetchGroup(id: string): Promise<Group> {
    const map: Record<string, unknown> = {
      limit: 1,
      offset: 0,
      sortField: "id",
      sortWay: "ASC",
      filters: [{ field: "id", operator: "equals", value: id }],
    };
    const { data } = await Vue.$axios.post<RawPage<RawGroup>>(`${BACKEND_BASE_URL_READS}/api/v1/group`, map);
    const result = data.results || data.projections;
    const { data: assignedServicePointsFromGroupUUID } = await Vue.$axios.get<AssignedServicePointParent>(
      `${BACKEND_BASE_URL_READS}/api/v1/group/${id}/servicepoints`
    );
    if (result && result.length > 0) {
      const group = new Group(result[0]);
      group.assignedServicePoints = assignedServicePointsFromGroupUUID.assignedServicePoints;
      return group;
    } else {
      throw new Error("Group not found");
    }
  }

  async createGroup(group: GroupDTO) {
    await Vue.$axios.put(`${BACKEND_BASE_URL}/api/v1/group`, group);
  }

  async updateGroupDescription(groupDescription: string, groupId: string) {
    const map: Record<string, unknown> = {
      description: groupDescription,
    };

    await Vue.$axios.patch(`${BACKEND_BASE_URL}/api/v1/group/${groupId}`, map);
  }

  async renameGroup(groupId: string, newGroupName: string) {
    await Vue.$axios.patch(`${BACKEND_BASE_URL}/api/v1/group/${groupId}/rename`, newGroupName);
  }

  async deleteGroup(groupId: string) {
    await Vue.$axios.delete(`${BACKEND_BASE_URL}/api/v1/group/${groupId}`);
  }

  async fetchAutomaticRules(groupId: string): Promise<AutomaticRules> {
    const { data } = await Vue.$axios.get<RawAutomaticRules>(
      `${BACKEND_BASE_URL_READS}/api/v1/group/${groupId}/rules/criteria`
    );
    return new AutomaticRules(data);
  }

  async addRules(groupId: string, rules: Rule[]): Promise<void> {
    const mapRules: Record<string, unknown>[] = rules.map((rule) => {
      const record: Record<string, unknown> = {
        fieldName: rule.fieldName,
        fieldOperator: rule.fieldOperator,
        fieldValue: rule.fieldValue,
        ruleOperator: rule.ruleOperator,
        fieldType: rule.fieldType,
      };
      return record;
    });
    await Vue.$axios.patch(`${BACKEND_BASE_URL}/api/v1/group/${groupId}/rules/automatic`, mapRules);
  }

  async addCashTodays(groupId: string, cashTodays: string[]): Promise<void> {
    await Vue.$axios.put(`${BACKEND_BASE_URL}/api/v1/groups/${groupId}/servicepoints`, cashTodays);
  }

  async deleteAutomaticCondition(groupId: string, conditionId: string) {
    await Vue.$axios.delete(`${BACKEND_BASE_URL}/api/v1/group/${groupId}/rules/${conditionId}/automatic`);
  }

  async deleteAllAutomaticRules(groupId: string) {
    const emptyRule: [] = [];
    await Vue.$axios.patch(`${BACKEND_BASE_URL}/api/v1/group/${groupId}/rules/automatic`, emptyRule);
  }
}
