import { RawAlert } from "@/entities/alert/alert.types";
import { Alert } from "@/entities/alert/alert";

export const mockRawAlerts = (): RawAlert[] => [
  {
    value: 135.5,
    option: "USD",
    recipients: "email1@gmail.com",
    alertType: "AMOUNT",
    alertOptions: [],
    placeholder: "",
  },
];

export const mockAlerts = (data: RawAlert[] = mockRawAlerts()): Alert[] => data.map((item) => new Alert(item));
