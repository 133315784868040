import Pageable from "@/entities/pageable/pageable_collection";
import { ServicePoint, ServicepointType } from "@/entities";
import { ResponseCreate } from "@/services";
import { DeviceServicePoint } from "@/entities/deviceservicepoint";

export interface ServicePointService {
  fetchServicePoints(map: Record<string, unknown>): Promise<Pageable<ServicePoint>>;
  fetchServicePointsByGroupRules(groupId: string, map: Record<string, unknown>): Promise<Pageable<ServicePoint>>;
  createServicePoint(servicePoint: ServicePoint): Promise<ResponseCreate>;
  createServicePointAndDevice(servicePoint: ServicePoint, device: DeviceServicePoint): Promise<ResponseCreate>;
  updateServicePoint(servicePoint: ServicePoint): Promise<void>;
  updateDeviceServicePoint(device: DeviceServicePoint): Promise<void>;
  changeStatus(servicePoint: ServicePoint): Promise<void>;
  fetchServicePointTypes(): Promise<ServicepointType[]>;
}

export interface IServicePointFilter {
  text?: string;
  countryCode?: string;
  entityCode?: string;
}

export interface IServicePointPageFilter {
  code?: string;
  name?: string;
  refresh: boolean;
  active: boolean;
}

export const iniServicePointPageFilter: IServicePointPageFilter = {
  code: "",
  name: "",
  refresh: false,
  active: true,
};

export const iniServicePointFilter: IServicePointFilter = {
  text: "",
  countryCode: "",
  entityCode: "",
};

export interface IRequestServicePointFilter {
  servicePointName?: string;
  servicePointCode?: string;
  countryCode?: string;
  entityCode?: string;
  deviceCode?: string;
  centerId?: string;
  active?: boolean;
}

export const iniRequestServicePointFilter: IRequestServicePointFilter = {
  servicePointName: "",
  countryCode: "",
  entityCode: "",
  deviceCode: "",
};

export interface ServicePointServiceMock {
  fetchServicePoints: jest.Mock<Promise<Pageable<ServicePoint>>>;
  fetchServicePointsByGroupRules: jest.Mock<Promise<Pageable<ServicePoint>>>;
  createServicePoint: jest.Mock<Promise<ResponseCreate>>;
  createServicePointAndDevice: jest.Mock<Promise<ResponseCreate>>;
  updateServicePoint: jest.Mock<Promise<void>>;
  updateDeviceServicePoint: jest.Mock<Promise<void>>;
  changeStatus: jest.Mock<Promise<void>>;
  fetchServicePointTypes: jest.Mock<Promise<ServicepointType[]>>;
}
