
import { Center, Entity } from "@/entities";
import { i18n } from "@/i18n";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PButton, PModal } from "@/common/components";
import CenterForm from "@/components/centers/center-form/CenterForm.vue";

@Component({
  name: "center-modal",
  i18n,
  components: {
    PModal,
    PIcon,
    PButton,
    CenterForm,
  },
})
export class CenterModal extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.showModal = this.value;
  }
  @Prop({ required: false, default: true }) isModify!: boolean;
  @Prop({ required: true }) entity!: Entity;
  @Prop({ required: true }) center!: Center;
  @Prop({ required: false, default: "button.btn_save" }) textActionName!: string;
  showModal = false;

  closeModal(): void {
    this.showModal = false;
    this.$emit("input", false);
  }

  get titleModal(): string {
    return this.isModify
      ? this.$t("centerPage.modal.modal_edit_title") + this.center.name
      : (this.$t("centerPage.modal.modal_create_title") as string);
  }

  saveCenter(center: Center, update: boolean, action: string): void {
    if (update) {
      this.$services.center.updateCenter(center).then(() => {
        Vue.swal({
          icon: "success",
          title: this.$i18n.t("centerPage.modal.success"),
          showConfirmButton: false,
          timer: 2000,
        });
        this.$emit("refresh");
        this.closeModal();
      });
    } else {
      this.$services.center.createCenter(center).then((response) => {
        Vue.swal({
          icon: "success",
          title: this.$i18n.t("centerPage.modal.success_create"),
          showConfirmButton: false,
          timer: 2000,
        });
        this.$emit("refresh");
        if (action === "next") {
          center.id = response.uuid;
          this.$emit("next", center);
        }
        this.closeModal();
      });
    }
  }
}

export default CenterModal;
