import { RawDevice } from "@/entities";

export class Device {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly countryCode: string;
  readonly currencies: string[];
  readonly ip: string;
  readonly port: string;
  readonly type: string;
  readonly model: string;
  readonly manufacturer: string;
  readonly solutionType: string;
  readonly delegationCode: string;
  readonly lastUpdate: string;
  readonly noteCapacity: number;
  readonly isMulticlient: boolean;
  readonly servicePointCount: number;

  constructor(data: RawDevice) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.code = data.code || "";
    this.ip = data.ip || "";
    this.port = data.port || "";
    this.type = data.type || "";
    this.model = data.model || "";
    this.countryCode = data.countryCode || "";
    this.manufacturer = data.manufacturer || "";
    this.solutionType = data.solutionType || "";
    this.delegationCode = data.delegationCode || "";
    this.lastUpdate = data.lastUpdate || "";
    this.currencies = data.currencies || "";
    this.noteCapacity = data.noteCapacity || 0;
    this.isMulticlient = data.isMulticlient || false;
    this.servicePointCount = data.servicePointCount || 0;
  }
}
