
import { Vue, Component } from "vue-property-decorator";
import { PFormSelect, PFormInputText, PDropDownContainer, SelectType, PButton } from "@/common/components";
import { PRow } from "@/common/components/look/PRow";
import { Group } from "@/entities";

@Component({
  name: "users-drop-down-group",
  components: {
    PRow,
    PFormSelect,
    PFormInputText,
    PDropDownContainer,
    PButton,
  },
})
export class UserDropDownGroup extends Vue {
  selectItemFilterProperties = "";
  link = "link";
  options: Group[] = [];
  filterProperties: SelectType[] = [];

  async created(): Promise<void> {
    const data = await this.$services.group.fetchGroups();
    this.options = data.results;
    this.options?.forEach((option) => {
      this.filterProperties.push({ text: option.name as string, value: option.name as string });
    });
  }

  setItem(item: string): void {
    this.selectItemFilterProperties = item;
  }

  filter(): void {
    this.$emit("filter-by-group", this.selectItemFilterProperties);
  }

  reset(): void {
    this.selectItemFilterProperties = "Seleccione un Grupo";
    this.$emit("clear-filter-by-group");
  }
}
export default UserDropDownGroup;
