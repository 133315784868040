import { Center, RawCenter } from "@/entities";
import { BACKEND_BASE_URL, BACKEND_BASE_URL_READS, ResponseCreate } from "@/services";
import Vue from "vue";
import { CenterService } from "./center.types";
import Pageable from "@/entities/pageable/pageable_collection";
import { RawPage } from "@/entities/pageable/pageable_types";

export class CenterServiceImpl implements CenterService {
  async fetchCenters(map: Record<string, unknown>): Promise<Pageable<Center>> {
    const { data } = await Vue.$axios.post<RawPage<RawCenter>>(`${BACKEND_BASE_URL_READS}/api/v1/me/centers`, map);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawCenter) => new Center(rawCenter)) ?? [],
    };
  }

  async fetchCentersV2(map: Record<string, unknown>): Promise<Pageable<Center>> {
    const { data } = await Vue.$axios.post<RawPage<RawCenter>>(`${BACKEND_BASE_URL_READS}/api/v2/me/centers`, map);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawCenter) => new Center(rawCenter)) ?? [],
    };
  }

  async fetchCentersByEntityId(entityId: string, map: Record<string, unknown>): Promise<Pageable<Center>> {
    map["entityId"] = entityId;
    delete map["filters"];
    const { data } = await Vue.$axios.post<RawPage<RawCenter>>(`${BACKEND_BASE_URL_READS}/api/v2/me/centers`, map);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawCenter) => new Center(rawCenter)) ?? [],
    };
  }

  async uploadImage(entityId: string, image: FormData): Promise<void> {
    await Vue.$axios.put(`${BACKEND_BASE_URL}/api/v1/entities/${entityId}/image`, image, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  async deleteImage(entityId: string): Promise<void> {
    await Vue.$axios.delete(`${BACKEND_BASE_URL}/api/v1/entities/${entityId}/image`);
  }

  async createCenter(center: Center): Promise<ResponseCreate> {
    const { data } = await Vue.$axios.post(`${BACKEND_BASE_URL}/api/v1/center`, {
      entityId: center.entityId,
      name: center.name,
      code: center.code,
      delegationId: center.delegationId,
      city: center.cityName,
      province: center.provinceName,
      zipCode: center.zipCode,
      currencyLimits: center.limits,
    });
    return data;
  }

  async updateCenter(center: Center): Promise<void> {
    await Vue.$axios.patch(`${BACKEND_BASE_URL}/api/v1/center/${center.id}`, {
      name: center.name,
      delegationId: center.delegationId,
      city: center.cityName,
      province: center.provinceName,
      zipCode: center.zipCode,
      currencyLimits: center.limits,
    });
  }

  async changeStatus(center: Center): Promise<void> {
    await Vue.$axios.patch<void>(`${BACKEND_BASE_URL}/api/v1/center/${center.id}`, {
      active: center.active,
    });
  }
}
