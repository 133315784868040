
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  name: "p-form-input-text-search",
  components: { PIcon },
})
export class PFormInputTextSearch extends Vue {
  @Prop({ required: true }) value!: string;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.valueLocal = this.value;
  }
  @Prop({ required: false, default: "" }) placeholder!: string;
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false, default: false }) readonly!: boolean;
  @Prop({ required: false, default: true }) show!: boolean;
  @Ref("inputRef") readonly inputRef!: any;
  valueLocal = "";
  showInput = false;
  mounted() {
    this.showInput = this.show;
  }

  get classComp() {
    if (this.valueLocal != "") {
      return "search_PFormInputTextSearch active_PFormInputTextSearch";
    } else {
      return "search_PFormInputTextSearch";
    }
  }

  input(): void {
    this.$emit("input", this.valueLocal);
  }

  click() {
    this.showInput = true;
    this.$nextTick(() => {
      this.inputRef.focus();
    });
    this.$emit("click");
  }

  mouseleave(): void {
    if (this.valueLocal === "" && !this.show) {
      this.showInput = false;
    }
  }

  change(e: { key: string, target: { value: any } }): void {
    if (e.key === "Enter") {
      this.$emit("enter-pressed", this.valueLocal);
    }
    this.$emit("change", e.target.value);
  }
}
export default PFormInputTextSearch;
