import { RawServicePointType } from "@/entities/servicepointtype/servicepointtype.types";
import { ServicepointType } from "@/entities/servicepointtype/servicepointtype";

export const mockRawServicePointsType = (): RawServicePointType[] => [
  {
    aggregateId: "3e1f8d2a-6b9c-4d5e-8f7a-2b4c9d6e3f1a",
    id: "3e1f8d2a-6b9c-4d5e-8f7a-2b4c9d6e3f1a",
    code: "0",
    name: "Ventanilla Banco",
  },
];

export const mockServicePointsType = (data: RawServicePointType[] = mockRawServicePointsType()): ServicepointType[] =>
  data.map((item) => new ServicepointType(item));
