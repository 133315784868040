
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PPagination from "../PPagination/PPagination.vue";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import PFormInputPredictiveSearch from "../PFormInput/PFormInputPredictiveSearch.vue";
import PFormCheckBox from "../PFormCheckBox/PFormCheckBox.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PTableType, FieldPTableType } from "./pTable.type";
import { iTranslationsTable, translations } from "./languajesTable";

@Component({
  components: {
    PPagination,
    PDropDownContainer,
    PFormInputPredictiveSearch,
    PFormCheckBox,
    PIcon,
  },
})
export class PTable extends Vue {
  @Prop({ required: true }) value!: PTableType;
  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.fieldsFormatter = this.fieldsFormatter.concat(this.value.fields);

    this.initValoresTdAnteriores();
  }
  @Prop({ required: true }) items!: any[];
  @Watch("items", { deep: true, immediate: true })
  onChangeItems(): void {
    if (!this.paginationServer) {
      this.valueLocal.pagination.totalElements = this.items.length;
    }
  }
  @Prop({ required: false, default: "" }) tableClass!: string;
  @Prop({ required: false, default: false }) pagination!: boolean;
  @Prop({ required: false, default: false }) paginationServer!: boolean;
  @Prop({ required: false, default: "No hay datos que mostrar" }) textEnmty!: string;
  @Prop({ required: false, default: "es" }) language!: string;

  traductor: iTranslationsTable = translations[0].translations;
  valueLocal: PTableType = {
    fields: [],
    pagination: {
      pageSize: 10,
      totalElements: 100,
      currentPage: 1,
    },
    order: {
      orderField: "divisa",
      orderDirection: "DESC",
      groupedFields: [],
    },
  };
  fieldsFormatter: FieldPTableType[] = []; //lo necesito porque sino pierdo las funciones de formatter
  valoresTdAnteriores: string[] = [];
  filterCheck: string[] = [];
  hayDatos = false;
  created(): void {
    const languaje = translations.find((tran) => tran.language === this.language)?.translations;
    if (languaje) {
      this.traductor = languaje;
    }
  }

  get fields(): FieldPTableType[] {
    const fields = this.value.fields.filter((fie: FieldPTableType) => fie?.show);

    return fields;
  }
  get itemsComp(): any[] {
    if (this.paginationServer) {
      if (this.items.length > 0) {
        this.hayDatos = true;
      }
      return this.items;
    } else {
      let itemsTemp: any[] = this.items.slice();
      //Lo primero ordenamos por el campo que se quiere ordenar
      itemsTemp = itemsTemp.sort((x, y) => {
        return this.sortLocal(x, y, this.valueLocal.order.orderField, this.valueLocal.order.orderDirection);
      });

      //reocrremos los campos con agrupaciones de forma inversa y ordenamos
      const groupedFieldsReverse = this.valueLocal.order.groupedFields.slice().reverse();
      groupedFieldsReverse.forEach((key) => {
        let direction = "DESC";
        if (this.valueLocal.order.orderField === key) {
          direction = this.valueLocal.order.orderDirection;
        }
        itemsTemp = itemsTemp.sort((x, y) => {
          return this.sortLocal(x, y, key, direction);
        });
      });
      if (itemsTemp.length > 0) {
        this.hayDatos = true;
      }

      const iniItems =
        this.valueLocal.pagination.currentPage * this.valueLocal.pagination.pageSize -
        this.valueLocal.pagination.pageSize;
      const finItems = iniItems + this.valueLocal.pagination.pageSize;
      //

      return itemsTemp.slice(iniItems, finItems);
    }
  }
  givemeClassTypeField(field: FieldPTableType): string {
    if (field.type === "action") {
      return "tdActions_PTable";
    } else {
      return "";
    }
  }
  sortLocal(x: any, y: any, key: string, direction: string): number {
    if (direction === "DESC") {
      if (x[key] < y[key]) {
        return -1;
      }
      if (x[key] > y[key]) {
        return 1;
      }
      return 0;
    } else if (direction === "ASC") {
      if (x[key] < y[key]) {
        return 1;
      }
      if (x[key] > y[key]) {
        return -1;
      }
      return 0;
    } else {
      return 0;
    }
  }
  initValoresTdAnteriores(): void {
    const numfileds = this.value.fields.filter((fie: FieldPTableType) => fie?.show).length;
    this.valoresTdAnteriores = new Array(numfileds);
  }
  formatFiled(field: any, item: any): any {
    const filedTemp: any = this.fieldsFormatter.find((fi: FieldPTableType) => fi.key == field.key);

    if (filedTemp?.formatter) {
      return filedTemp.formatter(item[field.key]);
    } else {
      return item[field.key];
    }
  }
  giveMeMaxSizeRowSpan(field: any, element: string, item: any, index: number, indexItem: number): string {
    let returnTemp = this.giveMeMaxSize(field, element);
    //returnTemp = index + " g:" + field.groupedField + " v:" + valorTd;
    if (field.fixColumn) {
      if (indexItem % 2 == 0) {
        returnTemp =
          returnTemp +
          "position: sticky; right: 0;background-color: #fafafa;box-shadow: 1px 0 rgba(0, 0, 0, 0.16) inset;";
      } else {
        returnTemp =
          returnTemp +
          "position: sticky; right: 0;background-color: #f1f1f1;box-shadow: 1px 0 rgba(0, 0, 0, 0.16) inset;";
      }
    }
    if (field.groupedField) {
      if (this.valoresTdAnteriores[index] === this.formatFiled(field, item)) {
        returnTemp = returnTemp + "display:none;";

        // returnTemp = returnTemp + " NOOO";
      }
      this.valoresTdAnteriores[index] = this.formatFiled(field, item);
    } else {
      this.valoresTdAnteriores[index] = "";
    }

    return returnTemp;
  }
  giveClassTh(field: FieldPTableType): string {
    if (field.fixColumn) {
      return "fixColumn";
    } else {
      return "";
    }
  }
  giveMeMaxSize(field: FieldPTableType, element: string): string {
    let returnTemp = "";
    if (field.width) {
      if (field.type != "action" && element === "divTd") {
        returnTemp = "";
      } else {
        returnTemp = "width:" + field.width + ";";
      }
    } else {
      const numFields = this.fields.length;
      if (element === "divTd") {
        returnTemp = "width:100%;";
      } else {
        returnTemp = "max-width:" + 100 / numFields + "%;";
      }
    }

    return returnTemp;
  }
  selectAllFilterField(field: FieldPTableType, index: number): void {}
  optionsCheckFilterFields(field: FieldPTableType): any[] {
    let returnTemp: any[] = [];
    this.itemsComp.forEach((it) => {
      const valor = this.formatFiled(field, it);
      returnTemp.push({ value: valor, text: valor });
    });
    return returnTemp;
  }
  actionTable(field: any, accion: string): void {
    const fieldTemp: any = this.valueLocal.fields.find((fi: FieldPTableType) => fi.key === field.key);
    if (accion === "ASC") {
      this.valueLocal.order.orderField = field.key;
      this.valueLocal.order.orderDirection = "ASC";
      this.valueLocal.pagination.currentPage = 1;
    } else if (accion === "DESC") {
      this.valueLocal.order.orderField = field.key;
      this.valueLocal.order.orderDirection = "DESC";
      this.valueLocal.pagination.currentPage = 1;
    } else if (accion === "COMP") {
      //DEbemos comprobar si nuestro field esta en groupedFields y añadirlo al array segun el orden del field
      const estaFieldEnGroupedFields = this.valueLocal.order.groupedFields.find((gr) => gr === field.key);
      const indexfieldAInsertar = this.valueLocal.fields.findIndex((fi: FieldPTableType) => fi.key === field.key);
      if (!estaFieldEnGroupedFields) {
        //como no esta prodemos a insertarlo
        let posicionEnArray = 0;
        //pero antes tenemos que ver en que posicion lo vamos a insertar
        //para recorremos el groupedFields y vemos los indices con los que corresponden los ya insertados en los fields
        this.valueLocal.order.groupedFields.forEach((gr, index) => {
          const indexYaInsertado = this.valueLocal.fields.findIndex((fi: FieldPTableType) => fi.key === gr);

          if (indexfieldAInsertar > indexYaInsertado) {
            posicionEnArray = index + 1;
          }
        });

        //como no esta lo añadimos
        this.valueLocal.order.groupedFields.splice(posicionEnArray, 0, field.key);
      }
      this.valueLocal.pagination.currentPage = 1;
      this.initValoresTdAnteriores();
      fieldTemp.groupedField = true;
    } else if (accion === "EXP") {
      var array = [2, 5, 9];

      var index = array.indexOf(5);
      if (index > -1) {
        array.splice(index, 1);
      }
      // array = [2, 9]

      //Debemos eliminar de groupedFields el campo que vallamos a desagrupar
      const indexAEliminar = this.valueLocal.order.groupedFields.findIndex((gr) => gr === field.key);
      if (indexAEliminar > -1) {
        this.valueLocal.order.groupedFields.splice(indexAEliminar, 1);
      }
      this.valueLocal.pagination.currentPage = 1;
      this.initValoresTdAnteriores();
      fieldTemp.groupedField = false;
    } else if (accion === "OCU") {
      this.initValoresTdAnteriores();
      fieldTemp.show = false;
    }

    this.$emit("input", this.valueLocal);
    if (this.paginationServer) {
      this.$emit("change", this.valueLocal);
    }
  }
  rowSpan(field: any, item: any, indexRow: number): number {
    if (field.groupedField) {
      const valorTemp = this.formatFiled(field, item);
      let numRowSpan = 0;
      let i = indexRow;
      while (i < this.itemsComp.length && valorTemp == this.formatFiled(field, this.itemsComp[i])) {
        i++;
        numRowSpan++;
      }
      return numRowSpan;
    } else {
      return 1;
    }
  }
  get iconOrder(): string {
    if (this.valueLocal.order.orderDirection == "ASC") {
      return "north";
    } else {
      return "south";
    }
  }
  get classFieldFixed(): string {
    const fieldFixed = this.value.fields.find((fi) => fi.fixColumn);
    if (fieldFixed) {
      return "classFieldFixed";
    } else {
      return "";
    }
  }
  isOrderFiels(filed: FieldPTableType): boolean {
    if (this.valueLocal.order.orderField === filed.key) {
      return true;
    } else {
      return false;
    }
  }

  change(): void {
    this.$emit("input", this.valueLocal);
    if (this.paginationServer) {
      this.$emit("change", this.valueLocal);
    }
  }
}
export default PTable;
