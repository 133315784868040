import {
  ServicePoint,
  RawServicePoint,
  mockRawCenters,
  mockRawDevices,
  mockRawEntities,
  mockRawServicePointsType,
} from "@/entities";

export const mockRawServicePoints = (): RawServicePoint[] => [
  {
    id: "030fe6cc-cf36-3a3f-8e4c-75bb005a465d",
    aggregateId: "030fe6cc-cf36-3a3f-8e4c-75bb005a465d",
    code: "70",
    name: "EDS MEDELLIN UNIVERSIDAD",
    center: mockRawCenters()[0],
    device: mockRawDevices()[0],
    entity: mockRawEntities()[0],
    servicePointType: mockRawServicePointsType()[0],
    earlyValue: false,
    currencyLimits: [],
    deviceCountByType: "",
    active: true,
  },
];

export const mockServicePoints = (data: RawServicePoint[] = mockRawServicePoints()): ServicePoint[] =>
  data.map((item) => new ServicePoint(item));
