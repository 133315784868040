
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { iniPaginationPTable, PaginationPTableType } from "@/common/components";
import Utilities from "@/components/commons/Utilities";
import { i18n } from "@/i18n";
import {
  iniRequestServicePointFilter,
  IRequestServicePointFilter,
  IServicePointPageFilter,
  PageRequestServicePoint,
  SERVICEPOINTTYPE_CASHTODAY,
} from "@/services";
import {
  Center,
  DeviceAlert,
  DeviceServicePoint,
  Entity,
  IField,
  iniRawCenter,
  iniRawDeviceServicePoint,
  iniRawEntity,
  iniRawServicePoint,
  ServicePoint,
} from "@/entities";
import { customDebounce } from "@/common/utils";
import { ServicePointGridOptions } from "@/components/servicePoints";
import ServicePointModal from "@/components/servicePoints/servicePoint-modal/ServicePointModal.vue";
import DeviceDetail from "@/components/devices/device-detail/DeviceDetail.vue";
import DeviceModalServicePoint from "@/components/devices/device-modal/DeviceModal.vue";

@Component({
  name: "center-grid",
  components: { ServicePointGridOptions, ServicePointModal, DeviceDetail, DeviceModalServicePoint },
})
export class ServicePointGrid extends Vue {
  @Prop({ required: true }) center!: Center;
  @Prop({ required: true }) entity!: Entity;
  @Prop({ required: true }) value!: { users: number; groups: number };
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  localEntity: Entity = new Entity({ ...iniRawEntity });
  localCenter: Center = new Center({ ...iniRawCenter });
  localServicePoint: ServicePoint = new ServicePoint({ ...iniRawServicePoint });
  localDevice: DeviceServicePoint = new DeviceServicePoint({ ...iniRawDeviceServicePoint });
  @Prop({ required: true }) filters!: IServicePointPageFilter;
  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.mapIUserFilterToRecord();
  }
  @Watch("params", { immediate: true, deep: true })
  onChangeParams(newParams: Record<string, unknown>, oldParams: Record<string, unknown>): void {
    if (JSON.stringify(newParams) === JSON.stringify(oldParams)){
      return;
    }
    if (this.firstLoad) {
      this.fetchServicePoints();
      this.firstLoad = false;
    } else customDebounce(() => this.fetchServicePoints(), 350);
  }
  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortFields[0].direction != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortFields[0].field != this.sort.sortField
    ) {
      this.paginationBack.sortFields[0].direction = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortFields[0].field = this.sort.sortField;
      this.updateParams();
    }
  }
  showEditModal = false;
  showModalDevice = false;
  selectedSP: ServicePoint = new ServicePoint({ ...iniRawServicePoint });
  firstLoad = true;
  valueLocal: { servicePoints: number } = {
    servicePoints: 0,
  };
  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: false,
    sortField: "id",
  };
  filters_field: IRequestServicePointFilter = { ...iniRequestServicePointFilter };
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = Utilities.pageableServicePoint(
    this.pagination.pageSize,
    null,
    this.sort.sortField,
    this.sort.sortDesc ? "DESC" : "ASC"
  );
  mapFilters: Record<string, string> = {
    name: "centerName",
    code: "centerCode",
  };
  servicePoints: ServicePoint[] = [];
  paginationBack: PageRequestServicePoint = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    filters: { ...iniRequestServicePointFilter },
    sortFields: [
      {
        field: this.params["sortField"] as string,
        direction: this.params["sortWay"] as string,
      },
    ],
  };
  paginationDefault = {
    limit: 100,
  };
  isCashToday = SERVICEPOINTTYPE_CASHTODAY;

  mapIUserFilterToRecord(): void {
    this.filters_field = { ...iniRequestServicePointFilter };
    if (!Utilities.isObjectEmpty(this.filters)) {
      this.filters_field = {
        servicePointName: this.filters.name,
        servicePointCode: this.filters.code,
        active: this.filters.active,
        countryCode: this.entity.countryCode,
        entityCode: this.entity.code,
        centerId: this.center.id,
      };
    }
    this.updateParams();
  }

  updateParams(): void {
    this.params = Utilities.pageableServicePoint(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.paginationBack.sortFields[0].field,
      this.paginationBack.sortFields[0].direction,
      this.filters_field
    );
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  async fetchServicePoints(): Promise<void> {
    const data = await this.$services.servicepoint.fetchServicePoints(this.params);
    this.servicePoints = data.results;
    this.pagination.totalElements = data.totalResult;
    this.valueLocal.servicePoints = data.totalResult;
    this.$emit("input", this.valueLocal);
  }

  changeActive(servicePoint: ServicePoint): void {
    Vue.swal({
      icon: "question",
      customClass: {
        confirmButton: "swal2_prosegur_confirm",
        cancelButton: "swal2_prosegur_cancel",
      },
      showCancelButton: true,
      cancelButtonText: this.$t("users.user_alert.cancel-button") as string,
      html: servicePoint.active
        ? this.$t("spPage.table.activeMessage", { name: servicePoint.name })
        : this.$t("spPage.table.inactiveMessage", { name: servicePoint.name }),
    }).then(async (response) => {
      if (response.isConfirmed) {
        await this.$services.servicepoint
          .changeStatus(servicePoint)
          .then(() => {
            Vue.swal({
              icon: "success",
              title: this.$i18n.t("spPage.modal.success"),
              showConfirmButton: false,
              timer: 2000,
            });
            this.fetchServicePoints();
          })
          .catch(() => this.fetchServicePoints());
      } else {
        servicePoint.active = !servicePoint.active;
      }
    });
  }

  get fields(): IField[] {
    return [
      {
        key: "cashtoday",
        label: this.$t("spPage.table.type") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "code",
        label: this.$t("spPage.table.code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "name",
        label: this.$t("spPage.table.name") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "device.name",
        label: this.$t("spPage.table.device") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "center.code",
        label: this.$t("spPage.table.center") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "device.code",
        label: this.$t("spPage.table.deviceId") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "earlyValue",
        label: this.$t("spPage.table.earlyValue") as string,
        sortable: true,
        class: "",
        thClass: "table-header text-center",
        tdClass: "table-cell table-cell-middle text-center",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "multiclient",
        label: this.$t("spPage.table.multiclient") as string,
        sortable: true,
        class: "",
        thClass: "table-header text-center",
        tdClass: "table-cell table-cell-middle text-center",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "active",
        label: this.$t("spPage.table.active") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "actions",
        label: "",
        sortable: false,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-expand table-cell-popup",
        visible: true,
        aux: true,
        code: "",
      },
    ];
  }

  openEditModal(servicePoint: ServicePoint): void {
    this.selectedSP = servicePoint;
    this.showEditModal = true;
  }

  async openModalDevice(servicePoint: ServicePoint): Promise<void> {
    try {
      if (servicePoint.entity) {
        this.localEntity = servicePoint.entity;
      }
      if (servicePoint.center) {
        this.localCenter = servicePoint.center;
      }
      if (servicePoint.device) {
        const deviceId = servicePoint.device.id;
        const alertsPromise = this.fetchDeviceAlerts(deviceId);
        const devicePromise = this.fetchDeviceById(deviceId);
        const [alerts, device] = await Promise.all([alertsPromise, devicePromise]);
        this.localDevice = device;
        this.localDevice.deviceAlerts = alerts;
      }
      this.localServicePoint = servicePoint;
      this.showModalDevice = true;
    } catch (error) {
      this.showModalDevice = false;
    }
  }

  action(data: { action: string; data: ServicePoint }): void {
    if (data.action === "edit") {
      this.openEditModal(data.data);
    }
    if (data.action === "edit-device") {
      this.openModalDevice(data.data);
    }
    if (data.action === "create") {
      this.openModalDevice(data.data);
    }
  }

  async fetchDeviceById(deviceId: string): Promise<DeviceServicePoint> {
    return await this.$services.device.fetchDeviceById(deviceId);
  }

  async fetchDeviceAlerts(deviceId: string): Promise<DeviceAlert[]> {
    return await this.$services.device.fetchDeviceAlerts(deviceId);
  }
}
export default ServicePointGrid;
