import { SelectType } from "@/common/components";
import { RawAlert } from "@/entities/alert/alert.types";

export class Alert {
  readonly value: number;
  option: string;
  readonly recipients: string;
  alertType: string;
  alertOptions: SelectType[];
  placeholder: string;

  constructor(data: RawAlert) {
    this.value = data.value;
    this.option = data.option;
    this.recipients = data.recipients;
    this.alertType = data.alertType;
    this.alertOptions = data.alertOptions;
    this.placeholder = data.placeholder;
  }
}
