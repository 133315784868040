
import { Center, Entity, ServicePoint } from "@/entities";
import { i18n } from "@/i18n";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PButton, PModal } from "@/common/components";
import ServicePointForm from "@/components/servicePoints/servicePoint-form/ServicePointForm.vue";
import { SERVICEPOINTTYPE_CASHTODAY } from "@/services";

@Component({
  name: "servicepoint-modal",
  i18n,
  components: {
    PModal,
    PIcon,
    PButton,
    ServicePointForm,
  },
})
export class ServicePointModal extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.showModal = this.value;
  }
  @Prop({ required: true }) center!: Center;
  @Prop({ required: true }) entity!: Entity;
  @Prop({ required: true }) servicePoint!: ServicePoint;
  @Prop({ required: false, default: true }) isModify!: boolean;
  @Prop({ required: false, default: "button.btn_save" }) textActionName!: string;
  showModal = false;
  isCashToday = SERVICEPOINTTYPE_CASHTODAY;

  closeModal(): void {
    this.showModal = false;
    this.$emit("input", false);
  }

  get titleModal(): string {
    return this.isModify
      ? this.$t("spPage.modal.modal_edit_title") + this.servicePoint.name
      : (this.$t("spPage.modal.modal_create_title") as string);
  }

  saveServicePoint(servicePoint: ServicePoint, update: boolean, action: string): void {
    if (servicePoint.servicePointType?.id === this.isCashToday) {
      this.$emit("next", servicePoint);
      this.closeModal();
      return;
    }
    if (update) {
      this.$services.servicepoint.updateServicePoint(servicePoint).then(() => {
        Vue.swal({
          icon: "success",
          title: this.$i18n.t("spPage.modal.success"),
          showConfirmButton: false,
          timer: 2000,
        }).finally(() => {
          this.$emit("refresh");
          this.closeModal();
        });
      });
    } else {
      this.$services.servicepoint.createServicePoint(servicePoint).then((response) => {
        Vue.swal({
          icon: "success",
          title: this.$i18n.t("spPage.modal.success_create"),
          showConfirmButton: false,
          timer: 2000,
        });
        this.$emit("refresh");
        this.closeModal();
        if (action === "next") {
          servicePoint.id = response.uuid;
          this.$emit("next", servicePoint);
        }
      });
    }
  }
}

export default ServicePointModal;
