import { RawLimit } from "@/entities/limit/limit.types";
import { Limit } from "@/entities/limit/limit";

export const mockRawLimits = (): RawLimit[] => [
  {
    value: 135.5,
    currency: "USD",
  },
];

export const mockLimits = (data: RawLimit[] = mockRawLimits()): Limit[] => data.map((item) => new Limit(item));
