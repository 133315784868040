
import { Component, Vue } from "vue-property-decorator";
import { PTabs, PTab } from "@/common/components";

@Component({
  name: "service-point-tabs",
  components: {
    PTabs,
    PTab,
  },
})
export class ServicePointTabs extends Vue {}
export default ServicePointTabs;
