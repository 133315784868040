
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IEntityFilter, iniEntityFilter } from "@/services";
import { Getter } from "vuex-class";
import { Country, User } from "@/entities";

@Component({
  name: "entity-filters",
})
export class EntityFilters extends Vue {
  @Prop({ required: false }) value!: IEntityFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  @Getter("getCountries") getCountries!: Country[];
  @Getter("getLoggedUser") getLoggedUser!: User;

  isBtnSearchDisabled = true;
  valueLocal: IEntityFilter = { ...iniEntityFilter };

  enableBtnSearch(): void {
    const requiredInputLength = 3;
    const hasValidCodeOrName =
      this.valueLocal.codeOrName &&
      typeof this.valueLocal.codeOrName === "string" &&
      this.valueLocal.codeOrName.length >= requiredInputLength;
    const hasValidCountry = !!this.valueLocal.country;
    this.isBtnSearchDisabled = !(hasValidCodeOrName || hasValidCountry);
  }

  search(): void {
    if (!this.isBtnSearchDisabled) {
      this.$emit("input", this.valueLocal);
    }
  }
}
export default EntityFilters;
