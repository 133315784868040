
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelectType } from "./pFormSelect.types";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
export class PFormSelectAlt extends Vue {
  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    if (this.value) {
      this.selectedItemValue = Object.assign(this.value);
    }
  }
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false }) nonSelectableOptions!: SelectType[];
  @Prop({ required: false, default: "Select an option" }) placeholder!: string;
  @Prop({ required: false, default: "primary" }) variant!: string;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 16 }) iSize!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;
  @Prop({ required: false, default: false }) isScrollable!: boolean;
  @Prop({ required: false, default: true }) showText!: boolean;

  selectedItemValue = "";
  optionSelect = this.isScrollable ? "optionsSelect_PFormSelect" + "_with_scrooll" : "optionsSelect_PFormSelect";
  pussOption = false;
  get variantCop(): string {
    let returnTemp: string = "inputSelect_PFormSelect_" + this.variant;
    if (this.variant === "button" && this.selectedItemValue != "") {
      returnTemp = returnTemp + " borderSelected_PFormSelect";
    }
    return returnTemp;
  }
  get variantExpandCop(): string {
    return "expand_moreSelect_PFormSelect_" + this.variant;
  }

  get selectedText(): string {
    // Find the selected option in the options array
    const selectedOption = this.options.find(opt => opt.value == this.selectedItemValue);

    // Check if the selected option is found and should show text
    if (selectedOption && this.showText) {
      return selectedOption.text;
    }

    // Check for non-selectable options if the selected option is not found
    if (this.nonSelectableOptions?.length > 0) {
      const nonSelectableFound = this.nonSelectableOptions.find(opt => opt.value == this.selectedItemValue);

      // Return the text of the non-selectable option if found and should show text
      if (nonSelectableFound && this.showText) {
        return nonSelectableFound.text;
      }
    }

    // Return the placeholder if no conditions are met
    return this.placeholder;
  }
  selectItem(itemValue: any): void {
    //this.selectedItemValue = Object.assign(itemValue);
    this.$emit("input", itemValue);
    this.$emit("change", itemValue);
    this.pussOption = false;
  }
  giveMeClass(itemValue: any): string {
    if (this.selectedItemValue == itemValue) {
      return "itemSelectSelected_PFormSelect";
    } else {
      return "itemSelect_PFormSelect";
    }
  }
}
export default PFormSelectAlt;
