import { SelectType } from "@/common/components";

export type RawAlert = {
  readonly value: number;
  option: string;
  readonly recipients: string;
  alertType: string;
  alertOptions: SelectType[];
  placeholder: string;
};

export const iniRawAlert: RawAlert = {
  value: 0,
  option: "",
  recipients: "",
  alertType: "",
  alertOptions: [],
  placeholder: "",
};
