
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Center, Currency, DeviceAlert, Entity, MachineType, ServicePoint } from "@/entities";
import PButton from "@/common/components/look/PButton/PButton.vue";
import { Getter } from "vuex-class";
import { SelectType } from "@/common/components";
import PCardShowHiden from "@/common/components/look/PCard/PCardShowHiden.vue";
import { Alert, iniRawAlert } from "@/entities/alert";
import { DeviceServicePoint, iniRawDeviceServicePoint } from "@/entities/deviceservicepoint";

@Component({
  components: {
    PButton,
    PCardShowHiden,
  },
})
export class DeviceForm extends Vue {
  @Prop({ required: true }) value!: DeviceServicePoint;
  @Prop({ required: true }) center!: Center;
  @Prop({ required: true }) entity!: Entity;
  @Prop({ required: true }) servicePoint!: ServicePoint;
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Prop({ required: false }) textCancelName!: string;
  @Prop({ required: false, default: true }) isModify!: boolean;
  @Getter("getCurrencies") getCurrencies!: Currency[];
  showModal = false;

  device: DeviceServicePoint = new DeviceServicePoint({ ...iniRawDeviceServicePoint });
  deviceTypes: SelectType[] = [];
  alertTypes: SelectType[] = [];
  alerts: Alert[] = [];
  validado = false;
  disableSubmit = true;

  created(): void {
    this.initializeData();
  }

  async initializeData(): Promise<void> {
    this.device = { ...this.value };
    if (this.device.deviceAlerts?.length > 0) {
      this.alerts = this.transformToAlerts(this.value.deviceAlerts);
    }
    await this.getTypes();
    this.getAlertTypes();
  }

  @Watch("device", { deep: true })
  onDeviceChange(): void {
    this.checkForChanges();
  }

  @Watch("alerts", { deep: true })
  onAlertsChange(): void {
    this.checkForChanges();
  }

  checkForChanges(): void {
    const isDeviceUpdated = JSON.stringify(this.device) !== JSON.stringify(this.value);
    const transformedAlerts = this.transformToAlerts(this.value.deviceAlerts);
    const isAlertsUpdated = JSON.stringify(this.alerts) !== JSON.stringify(transformedAlerts);
    this.disableSubmit = !(isDeviceUpdated || isAlertsUpdated);
  }

  cancel(): void {
    this.$emit("cancel");
  }

  action(action: string): void {
    this.transformDeviceAlerts();
    this.$emit("action", this.servicePoint, this.device, this.isModify, action);
  }

  get getCurrenciesOptions(): SelectType[] {
    return this.getCurrencies.map((currency) => ({
      value: currency.isoCode,
      text: currency.isoCode,
    }));
  }

  async getTypes(): Promise<void> {
    const machineTypes: MachineType[] = await this.$services.machine.fetchTypes();
    this.deviceTypes = machineTypes.map((it: MachineType) => ({
      text: this.$t(`deviceTypes.${it.name}`) as string,
      value: it.name,
    }));
  }

  getAlertOptionsByType(type: string) {
    return type === "AMOUNT"
      ? {
          options: this.getCurrenciesOptions,
          placeholder: "",
        }
      : {
          options: [
            { value: "NOTE_CAPACITY", text: this.$t("alertOptions.bill") as string },
            { value: "COIN_CAPACITY", text: this.$t("alertOptions.coin") as string },
          ],
          placeholder: "%",
        };
  }

  getAlertTypes() {
    this.alertTypes = [
      { value: "CAPACITY", text: this.$t("alertTypes.capacity") as string },
      { value: "AMOUNT", text: this.$t("alertTypes.balance") as string },
    ];
  }

  private transformToAlerts(alerts: DeviceAlert[]): Alert[] {
    return alerts?.map((alert) => ({
      alertOptions: this.getAlertOptionsByType(alert.alertType).options || [],
      option: alert.alertType === "AMOUNT" && alert.currency ? alert.currency : alert.alertType,
      value: alert.value,
      recipients: alert.recipients,
      alertType: alert.alertType === "NOTE_CAPACITY" ? "CAPACITY" : alert.alertType,
      placeholder: this.getAlertOptionsByType(alert.alertType).placeholder || "",
    }));
  }

  onAlertTypeChange(value: string, index: number) {
    const { options, placeholder } = this.getAlertOptionsByType(value);
    this.alerts[index].alertOptions = options;
    this.alerts[index].placeholder = placeholder;
  }

  addAlert(): void {
    this.alerts.push(new Alert(iniRawAlert));
  }

  removeAlert(index: number): void {
    this.alerts.splice(index, 1);
  }

  private transformDeviceAlerts(): void {
    this.device.deviceAlerts = this.alerts.map((alert) => ({
      currency: alert.alertType === "AMOUNT" ? alert.option : null,
      value: alert.value,
      recipients: alert.recipients,
      alertType: alert.alertType === "CAPACITY" ? alert.option : alert.alertType,
    }));
  }
}

export default DeviceForm;
