
import { Component, Prop, Vue } from "vue-property-decorator";
import { PDropDownContainer, PFormSelectAlt } from "@/common/components";
import { Center, Country, Delegation, Entity, Rule, User } from "@/entities";
import { PButton, PFormInputText, PFormInputTextSearch, PFormSelect, SelectType } from "@/common/components/look";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { EventBus } from "@/event-bus";
import { WizardToolTip } from "./WizardToolTip.vue";
import { customDebounce } from "@/common/utils";
import { Getter } from "vuex-class";
import { SearchEntitiesRequest } from "@/services";

@Component({
  name: "wizard-item",
  components: {
    PFormSelectAlt,
    PDropDownContainer,
    PFormInputTextSearch,
    PFormInputText,
    PFormSelect,
    PButton,
    PIcon,
    WizardToolTip,
  },
})
export class WizardItem extends Vue {
  @Prop({ required: true }) rule!: Rule;
  @Prop({ required: false }) countries!: string[];
  @Prop({ required: false, default: 1 }) index!: number;
  @Prop({ required: false, default: false }) localRules!: Rule[];

  @Getter("getLoggedUser") getLoggedUser!: User;

  filterProperties: SelectType[] = [
    { text: this.$t("groups.wizard.entity") as string, value: "entity", id: 1 },
    { text: this.$t("groups.wizard.center") as string, value: "center", id: 2 },
    { text: this.$t("groups.wizard.earlyValue") as string, value: "earlyValue", id: 4 },
    { text: this.$t("groups.wizard.delegation") as string, value: "delegation", id: 5 },
  ];

  filterPropertiesToShow: SelectType[] = [
    { text: this.$t("groups.wizard.country") as string, value: "country", id: 3 },
  ];

  selectedCondition = "";
  isLoading = true;
  conditionValue = "";
  textSearch = "";
  isActive = false;
  operatorSelected = "";
  options: SelectType[] = [];
  optionsFiltered: SelectType[] = [];
  showToolTip = false;
  isSearchBarDisabled = false;
  offsetUp = 0;

  earlyValueOptions = [
    { text: this.$t("groups.wizard.true") as string, value: "true" },
    { text: this.$t("groups.wizard.false") as string, value: "false" },
  ];
  pageLoadingTimeout: NodeJS.Timeout | undefined = undefined;

  async created(): Promise<void> {
    this.offsetUp =
      this.index > 4 ? (this.rule.fieldName.toLowerCase().includes("servicepointearlyvalue") ? 180 : 345) : 0;

    if (this.getLoggedUser.corporateAdmin) {
      this.filterProperties.push({ text: this.$t("groups.wizard.country") as string, value: "country", id: 3 });
    }
    const field = this.rule.fieldName.toLowerCase();
    if (field.includes("country")) {
      this.selectedCondition = "country";
    }
    if (field.includes("servicepointearlyvalue")) {
      this.selectedCondition = "earlyValue";
      this.optionsFiltered.push(...this.earlyValueOptions);
    }
    if (field.includes("delegation")) {
      this.selectedCondition = "delegation";
    }
    if (field.includes("entity")) {
      this.selectedCondition = "entity";
    }
    if (field.includes("center")) {
      this.selectedCondition = "center";
    }
    const parseValue = this.rule.showValue.split(";")[0];
    this.conditionValue = parseValue;
    this.operatorSelected = this.rule.ruleOperator;
    this.isLoading = false;
  }

  setConditionField(): void {
    this.conditionValue = "";
    this.optionsFiltered = [];
    this.textSearch = "";
    if (this.selectedCondition == "earlyValue") {
      this.optionsFiltered.push(...this.earlyValueOptions);
      this.isSearchBarDisabled = true;
    } else {
      this.isSearchBarDisabled = false;
    }
    this.emitConditionChangedEvent();
  }

  itemSelected(index: number): void {
    this.conditionValue = this.optionsFiltered[index].text;

    let field = "";
    let fieldType = "string";
    if (this.selectedCondition.toLocaleLowerCase().includes("country")) {
      field = "countryCode";
    } else if (this.selectedCondition.includes("delegation")) {
      field = "delegationId";
    } else if (this.selectedCondition.toLocaleLowerCase().includes("earlyvalue")) {
      field =
        "servicePoint" +
        this.selectedCondition.charAt(0).toUpperCase() +
        this.selectedCondition.substring(1, this.selectedCondition.length);
      fieldType = "boolean";
    } else {
      field = this.selectedCondition + "Id";
    }

    this.$emit(
      "rule-changed",
      this.rule.id,
      field,
      "equals",
      this.optionsFiltered[index].value,
      this.operatorSelected,
      fieldType
    );
  }

  addOperator(operator: string): void {
    this.operatorSelected = operator;
    this.emitConditionChangedEvent();
    this.emitAddExtraRuleEvent();
  }

  toggleOperator(): void {
    if (this.operatorSelected == "AND") {
      this.operatorSelected = "OR";
    } else {
      this.operatorSelected = "AND";
    }
    this.emitConditionChangedEvent();
  }

  deleteCondition(): void {
    this.$emit("deleteWizardCondition", this.rule.id);
  }

  emitConditionChangedEvent(): void {
    this.$emit("condition-changed", this.rule.id, this.operatorSelected);
  }

  emitAddExtraRuleEvent(): void {
    this.$emit("addExtraRule");
  }

  searchWithDebounce(): void {
    const debounceDuration = 450;
    customDebounce(() => this.search(), debounceDuration);
  }

  async search(): Promise<void> {
    this.disableFullPageLoading();
    const options: SelectType[] = [];
    const filters: Record<string, string>[] = [];

    const entityRequestParams: SearchEntitiesRequest = {
      limit: 100,
      offset: 0,
      sortField: "entityName",
      sortWay: "ASC",
      withCounters: false,
    };

    const query: Record<string, unknown> = {
      limit: 100,
      offset: 0,
      sortWay: "ASC",
      filters: [],
      withCounters: false,
    };

    const addFilter = (field: string, operator: string, value: string) => {
      filters.push({ field, operator, value });
    };

    const setSortField = () => {
      switch (this.selectedCondition) {
        case "center":
          query["sortField"] = "centerName";
          break;
        case "delegation":
          query["sortField"] = "delegationName";
          break;
        default:
          query["sortField"] = "entityName";
      }
    };

    const applyTextSearchFilters = () => {
      if (this.textSearch.match(/^[\w\s]+$/i) && this.textSearch.length > 0) {
        switch (this.selectedCondition) {
          case "entity":
            entityRequestParams.codeOrName = this.textSearch;
            break;
          case "center":
            addFilter("centerName", "FULL_TEXT_SEARCH", this.textSearch);
            break;
          case "delegation":
            addFilter("delegationName", "FULL_TEXT_SEARCH", this.textSearch);
            break;
          case "country":
            addFilter("countryCode", "FULL_TEXT_SEARCH", this.textSearch);
            break;
        }
      }
    };

    const applyAdvancedSearchFilters = () => {
      if (this.textSearch.match(/^\w+:\w+:\w+$/gi)) {
        const [field, operation, searchText] = this.textSearch.split(":");
        const operatorMap = {
          EQ: "EQUALS",
          FTS: "FULL_TEXT_SEARCH",
          LK: "ILIKE",
          CNT: "CONTAINS",
        };
        const operator = operatorMap[operation.toUpperCase() as keyof typeof operatorMap] || "EQUALS";
        const adjustedField = field.match(/country/i)
          ? "countryCode"
          : field.match(/delegation/i)
          ? "delegationName"
          : field;
        addFilter(adjustedField, operator, searchText);
      }
    };

    const fetchResults = async () => {
      let response: any = { totalResult: 0, results: [], projections: [] };
      switch (this.selectedCondition) {
        case "entity":
          response = await this.$services.entity.fetchEntitiesV2(entityRequestParams);
          response.results?.forEach((data: Entity) => {
            options.push({ text: data.name, value: data.id });
          });
          break;
        case "center":
          response = await this.$services.center.fetchCenters(query);
          response.results?.forEach((data: Center) => {
            options.push({ text: `${data.code}, ${data.name}`, value: data.id });
          });
          break;
        case "country":
          response = await this.$services.country.fetchCountriesByName("");
          response.countries.forEach((data: Country) => {
            const translationLabel = `country.${data.isoCode.toLowerCase()}`;
            const option = { text: this.$t(translationLabel) as string, value: data.isoCode };
            if (option.text.toLowerCase().includes(this.textSearch.toLowerCase())) {
              options.push(option);
            }
          });
          break;
        case "delegation":
          response = await this.$services.machine.fetchDelegationSearch(query);
          response.results?.forEach((data: Delegation) => {
            const option = { text: `${data.name}(${data.countryCode})`, value: data.id };
            if (!options.find((op) => op.value === option.value)) {
              options.push(option);
            }
          });
          break;
        case "earlyValue":
          options.push(...this.earlyValueOptions);
          break;
      }
    };

    if (this.countries && this.countries.length > 0) {
      const fieldCountry = this.selectedCondition.toLocaleLowerCase().includes("entity")
        ? "countryCode"
        : this.selectedCondition + "CountryCode";
      addFilter(fieldCountry, "IN", this.countries.join(","));
    }

    setSortField();
    applyTextSearchFilters();
    applyAdvancedSearchFilters();
    query["filters"] = filters;

    await fetchResults();

    this.optionsFiltered = options.filter(
      (option) => !this.localRules.map((value) => value.fieldValue).includes(option.value)
    );
    this.enableFullPageLoading();
  }

  disableFullPageLoading(): void {
    EventBus.$emit("disable-loading", true);
  }
  enableFullPageLoading(): void {
    if (this.pageLoadingTimeout) {
      clearTimeout(this.pageLoadingTimeout);
    }
    this.pageLoadingTimeout = setTimeout(() => {
      EventBus.$emit("disable-loading", false);
    }, 1000);
  }
}
export default WizardItem;
