
import { Component, Vue } from "vue-property-decorator";
import { EntityFilters, EntityGrid, EntityHeader } from "@/components";
import { IEntityFilter, iniEntityFilter } from "@/services";
import LinkHidden from "@/components/commons/link-hidden/LinkHidden.vue";

@Component({
  components: {
    EntityHeader,
    EntityFilters,
    EntityGrid,
    LinkHidden,
  },
})
export class EntityPage extends Vue {
  filters: IEntityFilter = { ...iniEntityFilter };

  refresh(): void {
    this.filters.refresh = !this.filters.refresh;
  }
}

export default EntityPage;
