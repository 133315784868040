
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "entity-link-hidden",
  components: {},
})
export class EntityLinkHidden extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.enabled = JSON.parse(JSON.stringify(this.value));
  }

  enabled = true;

  toggleActive(): void {
    this.enabled = !this.enabled;
    this.$emit("input", this.enabled);
  }
}

export default EntityLinkHidden;
