import { RawLimit } from "@/entities/limit/limit.types";

export class Limit {
  readonly value: number | string;
  readonly currency: string;

  constructor(data: RawLimit) {
    this.value = data.value;
    this.currency = data.currency;
  }
}
