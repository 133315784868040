
import { i18n } from "@/i18n";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ServicePoint } from "@/entities";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  i18n,
  components: { PIcon },
})
export class ServicePointGridOptions extends Vue {
  @Prop({ required: true }) value!: ServicePoint;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  open = false;
  valueLocal: Partial<ServicePoint> = {};

  openEditModal(): void {
    this.open = false;
    this.$emit("action", { action: "edit", data: this.valueLocal });
  }

  openCreateModal(): void {
    this.open = false;
    this.$emit("action", { action: "create", data: this.valueLocal });
  }
}
export default ServicePointGridOptions;
