import { Context, IActions } from "./actions.types";
import { Country, IStaticData, User } from "@/entities";
import { i18n } from "@/i18n";

export const actions: IActions = {
  // Root
  async initialize(context: Context): Promise<void> {
    const isLoggedIn = await this.$services.auth.getSignedIn();
    if (isLoggedIn) {
      await Promise.all([context.dispatch("fetchLoggedUser"), context.dispatch("updateLoggedUser")]).finally(
        async () => {
          const user: User | undefined = context.state.loggedUser;
          if (user) {
            if (user.corporateAdmin) {
              await context.dispatch("fetchCountries");
            } else {
              await context.dispatch("fetchCountriesForNotCorporateAdmin");
            }
            context.commit("SET_IS_SIGNED_IN", true);
          }
        }
      );
    }
  },

  setLocale(context: Context, locale: string) {
    context.commit("SET_LOCALE", locale);
  },

  setUrl(context: Context, url: string) {
    context.commit("SET_URL", url);
  },
  // Auth
  setSignedIn(context: Context, isSignedIn: boolean) {
    context.commit("SET_IS_SIGNED_IN", isSignedIn);
  },

  // User
  async fetchLoggedUser(context: Context): Promise<User> {
    const data = await this.$services.user.fetchMe();
    context.commit("SET_LOGGED_USER", data);
    return data;
  },
  // User
  async updateLoggedUser(): Promise<void> {
    await this.$services.user.updateMe();
  },

  // Country
  async fetchCountries(context: Context): Promise<void> {
    const data = await this.$services.country.fetchCountries();
    context.commit("SET_COUNTRIES", data.countries);
  },

  async fetchCountriesForNotCorporateAdmin(context: Context): Promise<void> {
    const user = await this.$services.user.fetchMe();
    const countryList: Country[] = [];
    if (user.assignedCountries) {
      user.assignedCountries?.forEach((countryCode) => {
        countryList.push(
          new Country({
            name: i18n.t("country." + countryCode.toLowerCase()) as string,
            isoCode: countryCode,
          })
        );
      });
    } else {
      countryList.push(
        new Country({
          name: i18n.t("country." + user.country.toLowerCase()) as string,
          isoCode: user.country,
        })
      );
    }
    countryList.sort((a, b) => a.name.localeCompare(b.name));
    context.commit("SET_COUNTRIES", countryList);
  },

  async fetchStaticData(context: Context): Promise<void> {
    const data: IStaticData = {
      currencies: [
        { isoCode: "EUR", description: "€" },
        { isoCode: "USD", description: "$" },
        { isoCode: "BRL", description: "R$" },
        { isoCode: "GBP", description: "£" },
        { isoCode: "COP", description: "$" },
        { isoCode: "AUD", description: "$" },
        { isoCode: "ARS", description: "$" },
        { isoCode: "CAD", description: "C$" },
        { isoCode: "CHF", description: "Fr." },
        { isoCode: "CLP", description: "$" },
        { isoCode: "CNY", description: "¥" },
        { isoCode: "CRC", description: "₡" },
        { isoCode: "CZK", description: "Kč" },
        { isoCode: "DKK", description: "kr" },
        { isoCode: "HKD", description: "HK$" },
        { isoCode: "HUF", description: "Ft" },
        { isoCode: "JPY", description: "¥" },
        { isoCode: "KRW", description: "₩" },
        { isoCode: "MXN", description: "$" },
        { isoCode: "MYR", description: "RM" },
        { isoCode: "NOK", description: "kr" },
        { isoCode: "NZD", description: "NZ$" },
        { isoCode: "PEN", description: "S/." },
        { isoCode: "PHP", description: "₱" },
        { isoCode: "PLN", description: "zł" },
        { isoCode: "PYG", description: "₲" },
        { isoCode: "VEF", description: "Bs." },
        { isoCode: "UYU", description: "$" },
        { isoCode: "SEK", description: "kr" },
        { isoCode: "SGD", description: "S$" },
        { isoCode: "THB", description: "฿" },
        { isoCode: "TWD", description: "NT$" },
        { isoCode: "GTQ", description: "Q" },
        { isoCode: "AED", description: "DH" },
        { isoCode: "BHD", description: "BD" },
        { isoCode: "BND", description: "B$" },
        { isoCode: "SAR", description: "SR" },
        { isoCode: "HRK", description: "kn" },
        { isoCode: "MAD", description: "dh" },
        { isoCode: "IEP", description: "£" },
        { isoCode: "INR", description: "₹" },
        { isoCode: "QAR", description: "QR" },
        { isoCode: "RON", description: "L" },
        { isoCode: "RUB", description: "₽" },
        { isoCode: "TRY", description: "TL" },
        { isoCode: "ZAR", description: "R" },
      ],
    };
    data.currencies = data.currencies.sort((a, b) => {
      if (a.isoCode < b.isoCode) {
        return -1;
      } else if (a.isoCode > b.isoCode) {
        return 1;
      } else return 0;
    });
    context.commit("SET_STATIC_DATA", data);
  },
};
