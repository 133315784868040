import { Center, Device, Entity, Limit, RawServicePoint, ServicepointType } from "@/entities";

export class ServicePoint {
  id: string;
  readonly name: string;
  readonly code: string;
  readonly earlyValue: boolean;
  entityId: string;
  centerId: string;
  readonly deviceId: string;
  readonly entity: Entity | null;
  readonly center: Center | null;
  readonly device: Device | null;
  limits: Limit[];
  typeId: string;
  servicePointType: ServicepointType | null;
  readonly deviceCountByType: string;
  active: boolean;

  constructor(data: RawServicePoint) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.code = data.code || "";
    this.earlyValue = data.earlyValue || false;
    this.entityId = data.entity ? data.entity.id : "";
    this.centerId = data.center ? data.center.id : "";
    this.deviceId = data.device ? data.device.id : "";
    this.entity = data.entity ? new Entity(data.entity) : null;
    this.center = data.center ? new Center(data.center) : null;
    this.device = data.device ? new Device(data.device) : null;
    this.servicePointType = data.servicePointType ? new ServicepointType(data.servicePointType) : null;
    this.limits = data.currencyLimits ? data.currencyLimits.map((value) => new Limit(value)) : [];
    this.servicePointType = data.servicePointType ? new ServicepointType(data.servicePointType) : null;
    this.typeId = data.servicePointType ? data.servicePointType.aggregateId : "";
    this.deviceCountByType = data.deviceCountByType || "";
    this.active = data.active || false;
  }
}
