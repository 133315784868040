
import { PFormInputText, PDropDownContainer, PFormSelect } from "@/common/components";
import { Component, Vue } from "vue-property-decorator";
import UserDropDownGroup from "./UserDropDownGroup.vue";
import { PRow } from "@/common/components/look/PRow";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  name: "user-group-filter",
  components: {
    PRow,
    PIcon,
    PFormInputText,
    PDropDownContainer,
    PFormSelect,
    UserDropDownGroup,
  },
})
export default class UserGroupFilter extends Vue {
  filter(value: string): void {
    this.$emit("filter", "group", value);
  }

  reset(): void {
    this.$emit("reset", "group");
  }
}
