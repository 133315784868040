export interface TranslationsPaginationPTable {
    [key: string]: string;
    page: string;
    rows: string;
  }
  export interface LanguagePaginationPTable {
    language: string;
    translations: TranslationsPaginationPTable;
  }
  export const translations: LanguagePaginationPTable[] = [
    {
      language: "es",
      translations: {
        showing: 'Mostrando',
        page: "Página",
        rows: "Filas",
        of: "de"
      },
    },
    {
      language: "en",
      translations: {
        showing: 'Showing',
        page: "Page",
        rows: "Rows",
        of: "of"
      },
    },
    {
      language: "en-US",
      translations: {
        showing: 'Showing',
        page: "Page",
        rows: "Rows",
        of: "of"
      },
    },
    {
      language: "pt",
      translations: {
        showing: 'Mostrando',
        page: "Página",
        rows: "Linhas",
        of: "de"
      },
    },
    {
      language: "de",
      translations: {
        showing: 'Zeigen',
        page: "Seite",
        rows: "Reihen",
        of: "von"
      },
    },
  ];

