
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Center, Country, Currency, Entity, iniRawCenter } from "@/entities";
import VisualizationToggle from "@/components/groups/group-permissions-tab/visualization-toggle/VisualizationToggle.vue";
import PCardShowHiden from "@/common/components/look/PCard/PCardShowHiden.vue";
import PButton from "@/common/components/look/PButton/PButton.vue";
import { iniRawLimit, Limit } from "@/entities/limit";
import { Getter } from "vuex-class";
import { PForm, PFormInputText, PFormSelect, SelectType } from "@/common/components";

@Component({
  components: {
    PButton,
    VisualizationToggle,
    PCardShowHiden,
    PFormInputText,
    PFormSelect,
    PForm,
  },
})
export class CenterForm extends Vue {
  @Prop({ required: true }) value!: Center;
  @Prop({ required: true }) entity!: Entity;
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Prop({ required: false }) textCancelName!: string;
  @Prop({ required: false, default: true }) isModify!: boolean;
  @Getter("getCurrencies") getCurrencies!: Currency[];
  @Getter("getCountries") getCountries!: Country[];
  showModal = false;
  conditionToCenterCode = "";

  center: Center = new Center({ ...iniRawCenter });
  validado = false;
  limits: Limit[] = [];
  delegationsOptions: SelectType[] = [];
  disableSubmit = true;

  @Watch("center", { deep: true })
  onCenterChange(): void {
    const isUpdateCenter = JSON.stringify(this.center) !== JSON.stringify(this.value);
    const isUpdateLimits = JSON.stringify(this.limits) !== JSON.stringify(this.value.limits);
    this.disableSubmit = !(isUpdateCenter || isUpdateLimits);
  }

  @Watch("limits", { deep: true })
  onLimitsChange(): void {
    const descriptionEmpty = this.limits.find((limit) => limit.currency === "");
    if (!descriptionEmpty) {
      this.center.limits = [...this.limits];
    }
  }

  created(): void {
    this.getDelegations();

    this.center = { ...this.value };
    this.limits = [...this.value.limits];
    this.center.entityId = this.entity.id;
    this.conditionToCenterCode = "required|alpha_num_center_code";
  }

  cancel(): void {
    this.$emit("cancel");
  }

  action(action: string): void {
    this.$emit("action", this.center, this.isModify, action);
  }

  addLimit(): void {
    this.limits.push(new Limit(iniRawLimit));
  }

  getOptionsAvailables(limit: Limit): SelectType[] {
    const options = JSON.parse(JSON.stringify(this.getCurrenciesOptions));
    if (limit.currency != "") {
      options.push({ value: limit.currency, text: limit.currency });
    }
    return options.sort((a: SelectType, b: SelectType) => {
      if (a.value < b.value) {
        return -1;
      } else if (a.value > b.value) {
        return 1;
      } else return 0;
    });
  }

  get getCurrenciesOptions(): SelectType[] {
    return this.getCurrencies
      .filter((c) => !this.limits.map((value1) => value1.currency).includes(c.isoCode))
      .map((currency) => {
        return { value: currency.isoCode, text: currency.isoCode };
      });
  }

  get shouldShowAddLimitButton() {
    return this.limits.every((limit) => this.getOptionsAvailables(limit).length > 1);
  }

  removeLimit(index: number): void {
    this.limits.splice(index, 1);
  }

  getDelegations(): void {
    const query = {
      limit: 100,
      offset: 0,
      sortField: "code",
      sortWay: "ASC",
      filters: [{ field: "delegationCountryCode", operator: "FULL_TEXT_SEARCH", value: this.entity.countryCode }],
    };
    this.$services.machine.fetchDelegationSearch(query).then((response) => {
      this.delegationsOptions = response.results.map((del) => {
        return { value: del.id, text: del.name };
      });
    });
  }

  get formattedEntity() {
    return `${this.entity.code} ${this.entity.name}`;
  }

  get hasCurrencies(): boolean {
    return this.limits.length < this.getCurrencies.length;
  }
}

export default CenterForm;
