import { RawSolutionHistory } from "./solutionHistory.types";

export class SolutionHistory {
  readonly id: string;
  readonly filename: string;
  readonly country: string;
  readonly status: string;
  readonly requestDate: string;
  readonly creationDate: string;
  readonly expirationDate: string;
  readonly icon: any;

  constructor(data: RawSolutionHistory) {
    this.id = data.id;
    this.filename = data.filename;
    this.country = data.country;
    this.status = data.status;
    this.requestDate = data.requestDate;
    this.creationDate = data.creationDate;
    this.expirationDate = data.expirationDate;
    this.icon =
      "data:image/svg+xml,%3Csvg data-v-6c46d4d2='' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 32 32'%3E%3Cdefs%3E%3Cpath id='vusxk6ktha' d='M17.333 1.333c.047 0 .093.003.138.007l-.138-.007c.067 0 .133.005.198.015l.076.013.063.015.07.02.06.022.077.03.047.023c.122.06.234.14.332.233l.02.02 9.333 9.333.02.02c.093.097.173.21.234.333l.021.046c.012.026.022.051.032.077l.02.06c.008.023.015.047.021.07l.015.064c.005.024.01.05.013.075l.008.061c.005.045.007.09.007.137v14.667c0 2.209-1.79 4-4 4H8c-2.21 0-4-1.791-4-4V5.333c0-2.209 1.79-4 4-4zM16 4L8 4c-.736 0-1.333.597-1.333 1.333v21.334C6.667 27.403 7.264 28 8 28h16c.736 0 1.333-.597 1.333-1.333V13.333h-8c-.693 0-1.262-.528-1.327-1.205L16 12V4zm7.448 6.667l-4.782-4.782v4.783h4.782z'%3E%3C/path%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg transform='translate(-620 -873) translate(143 873) translate(477)'%3E%3Cmask id='y3qbszf8wb' fill='%23fff'%3E%3Cuse xlink:href='%23vusxk6ktha'%3E%3C/use%3E%3C/mask%3E%3Cuse fill='%23000' fill-rule='nonzero' xlink:href='%23vusxk6ktha'%3E%3C/use%3E%3Cg fill='%23000' mask='url(%23y3qbszf8wb)'%3E%3Cpath d='M0 0H32V32H0z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
  }
}
