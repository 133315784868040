import { iniRawDelegation, RawDelegation, RawLimit } from "@/entities";

export type RawCenter = {
  readonly id: string;
  readonly active: boolean;
  readonly cityName: string;
  readonly code: string;
  readonly countryCode: string;
  readonly entityId: string;
  readonly name: string;
  readonly zipCode: string;
  readonly provinceName: string;
  readonly serviceCount: number;
  readonly currencyLimits: RawLimit[];
  readonly delegation: RawDelegation;
};

export const iniRawCenter: RawCenter = {
  id: "",
  code: "",
  name: "",
  countryCode: "",
  zipCode: "",
  provinceName: "",
  cityName: "",
  serviceCount: 0,
  currencyLimits: [],
  delegation: { ...iniRawDelegation },
  active: true,
  entityId: "",
};
