
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelectType } from "./pFormSelect.types";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
export class PFormSelectNarrow extends Vue {
  @Prop({ required: true }) value!: any;
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false, default: "Select an option" }) placeholder!: string;

  @Watch("value", { immediate: true })
  onChangeValue(): void {
    if (this.value) {
      this.selectedItemValue = Object.assign(this.value);
    }
  }
  pussOption = false;
  selectedItemValue = "";
  pulsadoApertura = false;

  get classInput(): string {
    if (this.pussOption) {
      return "inputSelectNarrowOpen dedo";
    } else {
      return "inputSelectNarrow dedo";
    }
  }

  get selectedText(): string {
    const optionFind = this.options.find((opt) => opt.value == this.selectedItemValue);
    if (optionFind) {
      return optionFind.text;
    } else {
      return this.placeholder;
    }
  }
  selectItem(itemValue: any): void {
    //this.selectedItemValue = Object.assign(itemValue);
    this.pussOption = false;
    this.$emit("input", itemValue);
    this.$emit("change", itemValue);
  }
  giveMeClass(itemValue: any): string {
    if (this.selectedItemValue == itemValue) {
      return "dedo itemSelectNarrowSelected";
    } else {
      return "dedo itemSelectNarrow";
    }
  }
}
export default PFormSelectNarrow;
