import { render, staticRenderFns } from "./CenterModal.vue?vue&type=template&id=06cf803d&scoped=true&"
import script from "./CenterModal.vue?vue&type=script&lang=ts&"
export * from "./CenterModal.vue?vue&type=script&lang=ts&"
import style0 from "./CenterModal.vue?vue&type=style&index=0&id=06cf803d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06cf803d",
  null
  
)

export default component.exports