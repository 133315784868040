import { CenterServiceMock } from "./center.types";

export const mockCenterService = (): CenterServiceMock => ({
  fetchCenters: jest.fn(),
  fetchCentersV2: jest.fn(),
  fetchCentersByEntityId: jest.fn(),
  uploadImage: jest.fn(),
  deleteImage: jest.fn(),
  createCenter: jest.fn(),
  updateCenter: jest.fn(),
  changeStatus: jest.fn(),
});
