import { mockUser } from "@/entities";
import { mockProvider } from "@/services";
import { mockActions } from "./actions";
import { mockGetters } from "./getters";
import { mockMutations } from "./mutations";
import { IRootState, IState, IStoreMock } from "./store.types";

export const mockRootState = (): IRootState => ({
  version: "0.0.0",
  locale: "",
  url: "",
  staticData: undefined,
});

export const mockState = (): IState => ({
  version: process.env.VUE_APP_VERSION as string,
  locale: "",
  url: "",
  staticData: undefined,
  isSignedIn: false,
  loggedUser: mockUser()[0],
  countries: [],
});

export const mockStore = (): IStoreMock => ({
  $services: mockProvider(),
  state: mockState(),
  actions: mockActions(),
  mutations: mockMutations(),
  getters: mockGetters(),
  replaceState: jest.fn(),
  dispatch: jest.fn(),
  commit: jest.fn(),
  subscribe: jest.fn(),
  subscribeAction: jest.fn(),
  watch: jest.fn(),
  registerModule: jest.fn(),
  unregisterModule: jest.fn(),
  hotUpdate: jest.fn(),
  hasModule: jest.fn(),
});
