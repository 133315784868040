
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Country, Currency, Entity, iniRawEntity } from "@/entities";
import VisualizationToggle from "@/components/groups/group-permissions-tab/visualization-toggle/VisualizationToggle.vue";
import PCardShowHiden from "@/common/components/look/PCard/PCardShowHiden.vue";
import PButton from "@/common/components/look/PButton/PButton.vue";
import { iniRawLimit, Limit } from "@/entities/limit";
import { Getter } from "vuex-class";
import { PForm, PFormInputText, PFormSelect, SelectType } from "@/common/components";

@Component({
  components: {
    PButton,
    VisualizationToggle,
    PCardShowHiden,
    PFormInputText,
    PFormSelect,
    PForm,
  },
})
export class EntityForm extends Vue {
  @Prop({ required: true }) value!: Entity;
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Prop({ required: false }) textCancelName!: string;
  @Prop({ required: false, default: true }) isModify!: boolean;
  @Getter("getCurrencies") getCurrencies!: Currency[];
  @Getter("getCountries") getCountries!: Country[];
  showModal = false;

  entity: Entity = new Entity({ ...iniRawEntity });
  validado = false;
  limits: Limit[] = [];
  disableSubmit = true;

  created(): void {
    this.entity = { ...this.value };
    this.limits = [...this.value.limits];
  }

  @Watch("entity", { deep: true })
  onEntityChange(): void {
    const isUpdateEntity = JSON.stringify(this.entity) !== JSON.stringify(this.value);
    const isUpdateLimits = JSON.stringify(this.limits) !== JSON.stringify(this.value.limits);
    this.disableSubmit = !(isUpdateEntity || isUpdateLimits);
  }

  @Watch("limits", { deep: true })
  onLimitsChange(): void {
    const descriptionEmpty = this.limits.find((limit) => limit.currency === "");
    if (!descriptionEmpty) {
      this.entity.limits = [...this.limits];
    }
  }

  cancel(): void {
    this.$emit("cancel");
  }

  action(action: string): void {
    this.$emit("action", this.entity, this.isModify, action);
  }

  addLimit(): void {
    this.limits.push(new Limit(iniRawLimit));
  }

  getOptionsAvailables(limit: Limit): SelectType[] {
    const options = JSON.parse(JSON.stringify(this.getCurrenciesOptions));
    if (limit.currency != "") {
      options.push({ value: limit.currency, text: limit.currency });
    }
    return options.sort((a: SelectType, b: SelectType) => {
      if (a.value < b.value) {
        return -1;
      } else if (a.value > b.value) {
        return 1;
      } else return 0;
    });
  }

  get getCurrenciesOptions(): SelectType[] {
    return this.getCurrencies
      .filter((c) => !this.limits.map((value1) => value1.currency).includes(c.isoCode))
      .map((currency) => {
        return { value: currency.isoCode, text: currency.isoCode };
      });
  }

  removeLimit(index: number): void {
    this.limits.splice(index, 1);
  }

  validateInput(value: any, index: number) {
    if (isNaN(value) || value === "") {
      this.limits[index] = { ...this.limits[index], value: "" };
    } else if (value < 0) {
      this.limits[index] = { ...this.limits[index], value: Math.abs(value) };
    } else {
      this.limits[index] = { ...this.limits[index], value: Number(value) };
    }
    this.$set(this.limits, index, { ...this.limits[index] });
  }

  get hasCurrencies(): boolean {
    return this.limits.length < this.getCurrencies.length;
  }
}

export default EntityForm;
