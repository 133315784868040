import { Center, mockRawDelegation, RawCenter } from "@/entities";

export const mockRawCenters = (): RawCenter[] => [
  {
    id: "be89ee31-e052-4a19-ab7d-e399e87c1ca8",
    entityId: "7741e61e-30ae-42c5-bba6-23225f9258e0",
    code: "123",
    name: "Zara",
    countryCode: "ES",
    zipCode: "28001",
    provinceName: "Madrid",
    cityName: "Centro Madrid",
    serviceCount: 0,
    currencyLimits: [],
    delegation: mockRawDelegation()[0],
    active: true,
  },
  {
    id: "7d65854b-9964-467b-90e3-5bedc92e3cbb",
    entityId: "71c365bc-928f-4aae-9a9e-3425d5a7ecfa",
    code: "310",
    name: "Figeroa",
    countryCode: "AR",
    provinceName: "Bs As",
    cityName: "Micro Centro",
    zipCode: "1400",
    serviceCount: 0,
    currencyLimits: [],
    delegation: mockRawDelegation()[0],
    active: true,
  },
  {
    id: "569b1b68-a7d0-4aae-a917-406ceb042c8a",
    entityId: "7741e61e-30ae-42c5-bba6-23225f9258e0",
    code: "274",
    name: "Monumento Español",
    countryCode: "ES",
    provinceName: "CABA",
    cityName: "CABA",
    zipCode: "1401",
    serviceCount: 0,
    currencyLimits: [],
    delegation: mockRawDelegation()[0],
    active: true,
  },
  {
    id: "7d65854b-9964-467b-90e3-5bedc92e3cbb",
    entityId: "71c365bc-928f-4aae-9a9e-3425d5a7ecfa",
    code: "314",
    name: "Calle Zurbano",
    countryCode: "ES",
    provinceName: " Madrid",
    cityName: " Madrid",
    zipCode: "2300",
    serviceCount: 0,
    currencyLimits: [],
    delegation: mockRawDelegation()[0],
    active: true,
  },
];

export const mockCenters = (data: RawCenter[] = mockRawCenters()): Center[] => data.map((item) => new Center(item));
