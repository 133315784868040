
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { iniServicePointPageFilter, IServicePointPageFilter } from "@/services";
import { PFormInputTextSearch } from "@/common/components";

@Component({
  name: "servicepoint-filters",
  components: {
    PFormInputTextSearch,
  },
})
export class ServicePointFilters extends Vue {
  @Prop({ required: true }) value!: IServicePointPageFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) {
      this.valueLocal = JSON.parse(JSON.stringify(this.value));
    }
  }

  valueLocal: IServicePointPageFilter = { ...iniServicePointPageFilter };

  search(): void {
    const isContentToSearch = this.valueLocal.name!.length > 2 || this.valueLocal.code!.length >= 1;
    const isBlank = this.valueLocal.name!.length === 0 && this.valueLocal.code!.length === 0;
    if (isBlank || isContentToSearch) {
      this.$emit("input", this.valueLocal);
    }
  }
}
export default ServicePointFilters;
