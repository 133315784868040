import { DeviceAlert, RawDeviceServicePoint } from "@/entities";

export class DeviceServicePoint {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly type: string;
  readonly manufacturer: string;
  readonly model: string;
  readonly ip: string;
  readonly port: number;
  readonly active: boolean;
  readonly currencies: string[];
  readonly manufacturerNoteCapacity: number;
  readonly realNoteCapacity: number;
  readonly prosegurNoteCapacity: number;
  readonly manufacturerCoinCapacity: number;
  readonly realCoinCapacity: number;
  readonly prosegurCoinCapacity: number;
  deviceAlerts: DeviceAlert[];

  constructor(data: RawDeviceServicePoint) {
    this.id = data.id || "";
    this.code = data.code || "";
    this.name = data.name || "";
    this.type = data.type || "";
    this.manufacturer = data.manufacturer || "";
    this.model = data.model || "";
    this.ip = data.ip || "";
    this.port = data.port || 0;
    this.active = data.active || true;
    this.currencies = data.currencies || "";
    this.manufacturerNoteCapacity = data.manufacturerNoteCapacity || 0;
    this.realNoteCapacity = data.realNoteCapacity || 0;
    this.prosegurNoteCapacity = data.prosegurNoteCapacity || 0;
    this.manufacturerCoinCapacity = data.manufacturerCoinCapacity || 0;
    this.realCoinCapacity = data.realCoinCapacity || 0;
    this.prosegurCoinCapacity = data.prosegurCoinCapacity || 0;
    this.deviceAlerts = data.deviceAlerts || [];
  }
}
