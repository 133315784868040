
import { Component, Vue } from "vue-property-decorator";
import { i18n } from "@/i18n";
import PButton from "@/common/components/look/PButton/PButton.vue";
import PModal from "@/common/components/look/PModal/PModal.vue";
import PFormInputText from "@/common/components/look/PFormInput/PFormInputText.vue";
import { Group, iniRawGroup } from "@/entities";
import { GroupForm } from "@/components";
import { GroupDTO } from "@/services";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  name: "user-new-group-modal",
  i18n,
  components: {
    PIcon,
    PModal,
    PFormInputText,
    PButton,
    GroupForm,
  },
})
export class UserNewGroupModal extends Vue {
  showModal = false;
  group: Group = new Group({ ...iniRawGroup });

  openModal(): void {
    this.group = new Group({ ...iniRawGroup });
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
  }

  async saveGroup(data: Group): Promise<void> {
    try {
      const groupToBack: GroupDTO = {
        name: data.name,
        description: data.description,
      };
      await this.$services.group.createGroup(groupToBack);
      Vue.swal({
        icon: "success",
        title: this.$t("groups.grid_alert.create_success"),
        showConfirmButton: false,
        timer: 2000,
      });
      this.closeModal();
      this.$emit("action");
      return;
    } catch (err) {
      console.log(err);
      Vue.swal({
        icon: "error",
        title: this.$i18n.t("groups.grid_alert.error_message"),
        showConfirmButton: false,
        timer: 2500,
      });
    }
  }
}
export default UserNewGroupModal;
