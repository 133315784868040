
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  PButton,
  PCheckboxDropdown,
  PDropDownContainer,
  PFormCheckBox,
  PFormSelect,
  PFormSwitch,
  PModal,
  SelectType,
} from "@/common/components";
import { Country, STATUS_DONE, STATUS_DONE_BACK, STATUS_PENDING, STATUS_PENDING_BACK, User } from "@/entities";
import UserDetailButtons from "@/components/user-detail/user-detail-buttons/UserDetailButtons.vue";
import { i18n } from "@/i18n";
import SwalFactory from "@/common/utils/swal";
import UserDetailGroup from "@/components/user-detail/user-detail-group/UserDetailGroup.vue";
import { Getter } from "vuex-class";
import { AssignedServicePoint } from "@/entities/assignedServicePoint";
import { Semaphore } from "@/common/utils/semaphore";

@Component({
  i18n,
  components: {
    PDropDownContainer,
    UserDetailButtons,
    UserDetailGroup,
    PModal,
    PButton,
    PCheckboxDropdown,
    PFormCheckBox,
    PFormSelect,
    PFormSwitch,
  },
})
export class UserDetailInfo extends Vue {
  @Prop({ required: true }) user!: User;
  @Prop({ required: true }) assignedServicePoints!: AssignedServicePoint[];
  @Getter("getLoggedUser") getLoggedUser!: User;
  @Getter("getCountries") getCountries!: Country[];
  semaphore = new Semaphore();

  filterProperties: SelectType[] = [];
  status = "";
  modalTitle = this.$i18n.t("userPage.save_changes");
  alertSuccess = this.$i18n.t("groups.update_successful");
  alertCouldntUpdateInformation = this.$i18n.t("users.user_alert.user-update-failed");
  filterPropertiesCountries: SelectType[] = [];
  assignedCountries: string[] = this.user.assignedCountries || [];
  removedCountries: string[] = [];
  isGlobal = this.user.corporateAdmin;

  async mounted(): Promise<void> {
    this.filterProperties = [
      { text: this.$t("status.done") as string, value: STATUS_DONE },
      { text: this.$t("status.pending") as string, value: STATUS_PENDING },
    ];
    this.status = this.user.status.toLowerCase();
    if (!this.getLoggedUser.corporateAdmin) {
      this.getLoggedUser.assignedCountries?.forEach((data: string) => {
        const translationLabel = `country.${data.toLowerCase()}`;
        this.filterPropertiesCountries.push({ text: this.$t(translationLabel) as string, value: data });
      });
    } else {
      this.getCountries.forEach((data: Country) => {
        const translationLabel = `country.${data.isoCode.toLowerCase()}`;
        this.filterPropertiesCountries.push({ text: this.$t(translationLabel) as string, value: data.isoCode });
      });
    }
  }

  get isStatusDoneOrCompleted(): boolean {
    return this.user.status === "DONE";
  }

  setStatusValue(): string {
    return this.status === STATUS_PENDING ? STATUS_PENDING_BACK : this.status === STATUS_DONE ? STATUS_DONE_BACK : "";
  }

  async launchConfModal(): Promise<void> {
    this.getRemovedCountries();
    if (this.removedCountries.length) {
      await new Promise((resolve) => {
        this.$emit("refresh", resolve);
      });
    }
    await this.showConfirmationModal();
  }

  async showConfirmationModal(): Promise<void> {
    Vue.swal({
      customClass: {
        confirmButton: "swal2_prosegur_confirm",
        cancelButton: "swal2_prosegur_cancel",
      },
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("button.btn_save") as string,
      cancelButtonText: this.$t("button.btn_cancel") as string,
      html: this.modalTitle,
    }).then(async (response) => {
      if (response.isConfirmed) {
        await this.validateRemovedCountries();
      }
    });
  }

  closeConfirmationModal(): void {
    location.reload();
  }

  getRemovedCountries(): void {
    this.removedCountries = this.user.assignedCountries?.filter((x) => !this.assignedCountries.includes(x)) || [];
  }

  get disableButton(): boolean {
    if (this.status === "") {
      return true;
    }

    if ((!this.assignedCountries || this.assignedCountries.length === 0) && !this.isGlobal) {
      return true;
    }

    const isStatusUpdated = this.status.toLowerCase() !== this.user.status.toLowerCase();
    const isCorporateAdminUpdated = this.user.corporateAdmin !== this.isGlobal;
    const isAssignedCountriesUpdated = !this.arraysAreEqual(this.assignedCountries, this.user?.assignedCountries || []);
    const isAnyMandatoryFieldsUpdated = isStatusUpdated || isCorporateAdminUpdated || isAssignedCountriesUpdated;
    return !isAnyMandatoryFieldsUpdated;
  }

  arraysAreEqual(arr1: any[], arr2: any[]): boolean {
    return new Set(arr1).size === new Set(arr2).size && new Set(arr1).size === new Set([...arr1, ...arr2]).size;
  }

  async validateRemovedCountries(): Promise<void> {
    const countriesDeleted: string[] = [];
    if (this.removedCountries.length) {
      let canUpdate = true;
      this.removedCountries.forEach((country) => {
        if (this.assignedServicePoints.some((value) => value.servicePoint.entity?.countryCode === country)) {
          canUpdate = false;
          countriesDeleted.push(country);
        }
      });
      if (canUpdate) {
        await this.saveChanges();
      } else {
        console.log("validateRemovedCountries, canUpdate value:", canUpdate);
        Vue.swal({
          customClass: {
            confirmButton: "swal2_prosegur_confirm",
          },
          icon: "error",
          showCancelButton: false,
          html:
            countriesDeleted.length > 1
              ? this.$t("userPage.info.warningRemoveCountries", { country: countriesDeleted.join(", ") })
              : this.$t("userPage.info.warningRemoveCountry", { country: countriesDeleted }),
        });
      }
    } else {
      await this.saveChanges();
    }
  }

  async saveChanges(): Promise<void> {
    // Check if assignedCountries are empty or undefined and show a warning
    if ((!this.assignedCountries || this.assignedCountries.length === 0) && !this.isGlobal) {
      SwalFactory.swalAlertWarning(this.alertCouldntUpdateInformation, this.closeConfirmationModal);
      return;
    }

    const fieldsToUpdate: Record<string, unknown> = {
      assignedCountries: this.assignedCountries.filter((country) => {
        return this.filterPropertiesCountries.some((c) => c.value === country);
      }),
      isCorporateAdmin: this.isGlobal,
    };
    await this.notifyChanges(fieldsToUpdate);
  }

  updateCountries(countries: string[]): void {
    this.assignedCountries = countries;
  }
  changeCheck(value: boolean): void {
    this.isGlobal = value;
  }

  async notifyChanges(fieldsToUpdate: Record<string, unknown>): Promise<void> {
    await this.semaphore
      .acquire(
        async () => await this.$services.user.updateUserById(this.user.id, fieldsToUpdate),
        async () => await this.$services.user.updateUserStatus(this.user.id, this.setStatusValue()),
        async () => SwalFactory.swalAlertSuccess(this.alertSuccess, this.closeConfirmationModal)
      )
      .catch(() => {
        SwalFactory.swalAlertWarning(this.alertCouldntUpdateInformation, this.closeConfirmationModal);
      });
  }
}
export default UserDetailInfo;
