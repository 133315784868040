
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { format } from "date-fns";
import PFormInputText from "../PFormInput/PFormInputText.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { TranslationsDatepicker, translations } from "./datepickerLanguages";

@Component({
  components: {
    PFormInputText,
    PIcon,
  },
})
export class PFormDatepicker extends Vue {
  @Prop({ required: true }) value!: Date;
  @Watch("value")
  onChangeValue(): void {
    this.initDate();
  }
  @Prop({ required: true }) id!: string;
  @Prop({ required: false }) translation!: TranslationsDatepicker;
  @Prop({ required: false, default: "es" }) language!: string;
  @Prop({ required: false, default: "dropdown" }) type!: string; // dropdown/flat/
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false }) readonly!: boolean;
  @Prop({ required: false, default: undefined }) state!: boolean;
  @Prop({ required: false, default: undefined }) invalidFeedback!: string;
  @Prop({ required: false, default: undefined }) startSelector!: Date | undefined;
  @Prop({ required: false, default: undefined }) endSelector!: Date | undefined;

  seeNTimes = 0;
  showDatePiker = false;
  monthDisplayed = 1;
  yearDisplayed = 2021;
  pressedOpening = false;
  translator: TranslationsDatepicker = translations[0].translations;

  created(): void {
    const language = this.language.split("-").shift();
    const translation = translations.find((tran) => tran.language === language)?.translations;
    if (translation) {
      this.translator = translation;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
  }
  mounted(): void {
    this.initDate();
    if (this.type === "dropdown") {
      const div = document.getElementById(this.id);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.addEventListener("click", function (e: any) {
        const layerDatePiker = document.getElementById(that.id + "Capa");
        if (layerDatePiker && !layerDatePiker.contains(e.target)) {
          if (that.showDatePiker && e.target.id != div?.id) {
            if (!that.pressedOpening) {
              that.showDatePiker = false;
            } else {
              that.pressedOpening = false;
            }
          }
        }
      });
    } else if (this.type === "flat") {
      this.showDatePiker = true;
    }
  }

  get giveMeClassTypo(): string {
    let returnTemp = "divDatePiker";
    if (this.type === "flat") {
      returnTemp = "divDatePikerFlat";
    }
    return returnTemp;
  }
  get giveMeiClass(): string {
    if (this.state) {
      return "p_icon_resuelto";
    } else {
      return "p_icon_error";
    }
  }
  get dateInput(): string {
    if (this.value) {
      return format(new Date(this.value), "dd/MM/yyyy");
    } else {
      return "";
    }
  }
  get year(): number {
    return this.yearDisplayed;
  }
  get month(): string {
    if (this.translator) {
      return this.translator["month_" + (this.monthDisplayed + 1)];
    } else {
      return "";
    }
  }
  get dayMonthPrevious(): number[] {
    const date01 = new Date(this.yearDisplayed, this.monthDisplayed, 1);
    let dayWeek = date01.getDay();
    const daysPrevious = [];
    if (dayWeek === 0) {
      dayWeek = 7;
    }
    for (let i = 1; i < dayWeek; i++) {
      daysPrevious.push(i);
    }
    return daysPrevious;
  }
  get daysMonthLater(): number[] {
    const numDayMonth = new Date(this.yearDisplayed, this.monthDisplayed + 1, 0).getDate();
    const lastDayDate = new Date(this.yearDisplayed, this.monthDisplayed, numDayMonth);
    const dayWeek = lastDayDate.getDay();
    const laterDays = [];
    for (let i = dayWeek; i < 7; i++) {
      laterDays.push(i);
    }
    return laterDays;
  }
  get daysMonth(): number[] {
    const numDayMonth = new Date(this.yearDisplayed, this.monthDisplayed + 1, 0).getDate();
    const days = [];
    for (let i = 1; i <= numDayMonth; i++) {
      days.push(i);
    }
    return days;
  }
  get daysWeek(): string[] {
    return [
      this.translator?.Mo,
      this.translator?.Tu,
      this.translator?.We,
      this.translator?.Th,
      this.translator?.Fr,
      this.translator?.Sa,
      this.translator?.Su,
    ];
  }

  initDate(): void {
    if (this.value) {
      const dateValue = new Date(this.value);
      this.monthDisplayed = dateValue.getMonth();
      this.yearDisplayed = dateValue.getFullYear();
    } else {
      const today = new Date();
      this.monthDisplayed = today.getMonth();
      this.yearDisplayed = today.getFullYear();
    }
  }
  selectDay(selectedDay: number): void {
    const dateTime = new Date(this.yearDisplayed, this.monthDisplayed, selectedDay);
    const dateTimeComparator = new Date(this.yearDisplayed, this.monthDisplayed, selectedDay, 0, 0, 1);
    let isInRange = true;
    if (this.startSelector && dateTimeComparator.getTime() < this.startSelector.getTime() - 86400000) {
      isInRange = false;
    }
    if (this.endSelector && dateTimeComparator.getTime() > this.endSelector.getTime()) {
      isInRange = false;
    }
    if (isInRange) {
      this.$emit("input", dateTime);
    }
  }
  classDaySelected(day: number): string {
    const dateTimeComparator = new Date(this.yearDisplayed, this.monthDisplayed, day, 0, 0, 1);
    let isInRange = true;
    let dayClass = "divDayMonthDisabled";
    if (this.startSelector && dateTimeComparator.getTime() < this.startSelector.getTime() - 86400000) {
      isInRange = false;
    }
    if (this.endSelector && dateTimeComparator.getTime() > this.endSelector.getTime()) {
      isInRange = false;
    }
    if (isInRange) {
      dayClass = "divDayMonthEnabled";
    }
    if (this.value) {
      const dateValue = new Date(this.value);
      const daySelected = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
      const dayCurrent = new Date(this.yearDisplayed, this.monthDisplayed, day);
      if (daySelected.getTime() === dayCurrent.getTime()) {
        return "daySelected";
      } else {
        return dayClass;
      }
    } else {
      return dayClass;
    }
  }
  showDatepicker(): void {
    if (!this.readonly) {
      this.showDatePiker = true;
      this.pressedOpening = true;
    }
  }
  yearAgo(): void {
    this.yearDisplayed = this.yearDisplayed - 1;
  }
  monthAgo(): void {
    if (this.monthDisplayed === 0) {
      this.monthDisplayed = 11;
      this.yearDisplayed = this.yearDisplayed - 1;
    } else {
      this.monthDisplayed = this.monthDisplayed - 1;
    }
  }
  today(): void {
    const today = new Date();
    this.monthDisplayed = today.getMonth();
    this.yearDisplayed = today.getFullYear();
    this.$emit("input", new Date(this.yearDisplayed, this.monthDisplayed, today.getDate()));
  }
  monthAhead(): void {
    if (this.monthDisplayed === 11) {
      this.monthDisplayed = 0;
      this.yearDisplayed = this.yearDisplayed + 1;
    } else {
      this.monthDisplayed = this.monthDisplayed + 1;
    }
  }
  yearAhead(): void {
    this.yearDisplayed = this.yearDisplayed + 1;
  }
}
export default PFormDatepicker;
