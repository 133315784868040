
import { Component, Vue } from "vue-property-decorator";
import { CenterFilters, CenterGrid, CenterHeader, CenterTabs } from "@/components";
import { Entity, iniRawEntity } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import { Breadcrum } from "@/components/commons";
import { ICenterFilter, iniCenterFilter, SearchEntitiesRequest } from "@/services";
import LinkHidden from "@/components/commons/link-hidden/LinkHidden.vue";

@Component({
  components: {
    CenterHeader,
    Breadcrum,
    CenterTabs,
    CenterGrid,
    CenterFilters,
    LinkHidden,
  },
})
export class CenterPage extends Vue {
  centerFilter: ICenterFilter = { ...iniCenterFilter };
  breadcrum: string[] = [this.$t("centerPage.breadcrum") as string];
  paths: string[] = ["/entities"];
  entityId!: string;
  entity: Entity = new Entity({ ...iniRawEntity });
  loaded = false;
  countResults: { centers: number } = {
    centers: 0,
  };

  created(): void {
    this.entityId = this.$route.params.id;
    this.fetchEntity();
  }

  refresh(): void {
    this.centerFilter.refresh = !this.centerFilter.refresh;
  }

  fetchEntity(): void {
    const requestParams: SearchEntitiesRequest = {
      aggregateId: this.entityId,
      limit: 100,
      offset: 0,
      sortField: "aggregateId",
      sortWay: "DESC",
    };
    this.$services.entity
      .fetchEntitiesV2(requestParams)
      .then((response: Pageable<Entity>) => {
        if (response.results.length == 1) {
          this.entity = response.results[0];
          this.breadcrum.push(this.entity.name!);
          this.loaded = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default CenterPage;
