
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PCol extends Vue {
  @Prop({ required: false }) md!: string;

  get giveMeClass(): string {
    if (this.md) {
      return `p-col-${this.md} p-col-padding`;
    }

    return "p-col p-col-padding";
  }
}
