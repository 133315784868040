
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/event-bus";
import { PFormInputTextSearch } from "@/common/components";
import { PRow } from "@/common/components/look/PRow";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PCol } from "@/common/components/look/PCol";

@Component({
  name: "entity-search",
  components: {
    PRow,
    PCol,
    PFormInputTextSearch,
    PIcon,
  },
})
export class EntitySearch extends Vue {
  search = "";
  entityName = "";

  setSearch(text: string): void {
    this.search = text;
  }

  searchEntity(): void {
    EventBus.$emit("search-entity", this.search);
  }
}
export default EntitySearch;
