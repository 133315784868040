
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Entity } from "@/entities";
import EntityForm from "@/components/entities/entity-form/EntityForm.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  name: "entity-modal",
  components: {
    EntityForm,
    PIcon,
  },
})
export class EntityModal extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.showModal = this.value;
  }
  @Prop({ required: false, default: true }) isModify!: boolean;
  @Prop({ required: true }) entity!: Entity;
  @Prop({ required: false, default: "button.btn_save" }) textActionName!: string;
  showModal = false;

  closeModal(): void {
    this.showModal = false;
    this.$emit("input", false);
  }

  get titleModal(): string {
    return this.isModify
      ? this.$t("entity.modal.modal_edit_title") + this.entity.name
      : (this.$t("entity.modal.modal_create_title") as string);
  }

  saveEntity(data: Entity, update: boolean, action: string) {
    if (update) {
      this.$services.entity.updateEntity(data).then((res) => {
        console.log(res);
        Vue.swal({
          icon: "success",
          title: this.$i18n.t("entity.modal.success"),
          showConfirmButton: false,
          timer: 2000,
        });
        this.$emit("refresh");
        this.closeModal();
      });
    } else {
      this.$services.entity.createEntity(data).then((response) => {
        Vue.swal({
          icon: "success",
          title: this.$i18n.t("entity.modal.success_create"),
          showConfirmButton: false,
          timer: 2000,
        });
        this.$emit("refresh");
        if (action === "next") {
          data.id = response.uuid;
          this.$emit("next", data);
        }
        this.closeModal();
      });
    }
  }
}
export default EntityModal;
