
import { Center, Entity, iniRawCenter } from "@/entities";
import { i18n } from "@/i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PButton, PModal } from "@/common/components";
import CenterForm from "@/components/centers/center-form/CenterForm.vue";
import CenterModal from "@/components/centers/center-modal/CenterModal.vue";
import Wizard from "@/components/commons/Wizard.vue";

@Component({
  name: "center-header",
  i18n,
  components: {
    PModal,
    PIcon,
    PButton,
    CenterForm,
    CenterModal,
    Wizard,
  },
})
export class CenterHeader extends Vue {
  @Prop({ required: true }) entity!: Entity;
  showModal = false;
  showModalCreate = false;
  errorModalMessage = "";
  imageDefault = require("@/assets/images/imago-prosegur@3x.png");
  imageLink: any = this.entity.imageLink;
  imageModalSrc: any = null;
  imageModalLoadedSuccessfully = true;
  imageHeaderLoadedSuccessfully = true;
  isModalBtnApplyDisabled = false;
  isModalBtnDeleteDisabled = false;
  isFirstLoaded = true;
  center: Center = new Center({ ...iniRawCenter });

  imageUrlAlt(event: any) {
    event.target.src = this.imageDefault;
    event.target.style.opacity = 0.1;
    this.imageModalLoadedSuccessfully = false;
    this.imageHeaderLoadedSuccessfully = false;
    this.isModalBtnApplyDisabled = true;
    this.isModalBtnDeleteDisabled = true;
  }

  changeImage(): void {
    this.showModal = true;
    this.errorModalMessage = "";
    this.imageModalSrc = null;
    this.isModalBtnApplyDisabled = true;
    this.isFirstLoaded = false;

    if (this.imageHeaderLoadedSuccessfully) {
      this.isModalBtnDeleteDisabled = false;
      this.imageModalLoadedSuccessfully = true;
    } else {
      this.isModalBtnDeleteDisabled = true;
      this.imageModalLoadedSuccessfully = false;
    }
  }

  closeModal(): void {
    this.showModal = false;
  }

  openModalCreate(): void {
    this.center = new Center({ ...iniRawCenter });
    this.showModalCreate = true;
  }

  refresh(): void {
    this.$emit("refresh");
  }

  deleteImage(): void {
    this.imageModalSrc = this.imageDefault;
    this.imageModalLoadedSuccessfully = false;
    this.isModalBtnDeleteDisabled = true;
    this.isModalBtnApplyDisabled = false;
    (this.$refs.fileInput as any).value = "";
  }

  uploadImage(): void {
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    fileInput.click();
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const validExtensions = ["image/png", "image/svg+xml", "image/jpg", "image/jpeg"];
    if (!validExtensions.includes(file.type)) {
      this.invalidModalImage();
      return;
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        if (img.width > 160 || img.height > 160) {
          this.invalidModalImage();
        } else {
          this.errorModalMessage = "";
          this.imageModalSrc = e.target.result;
          this.imageModalLoadedSuccessfully = true;
          this.isModalBtnApplyDisabled = false;
          this.isModalBtnDeleteDisabled = false;
        }
      };
    };
    reader.readAsDataURL(file);
  }

  invalidModalImage() {
    this.errorModalMessage = this.$t("entity.change-image.error_message") as string;
    this.imageModalLoadedSuccessfully = false;
    this.isModalBtnApplyDisabled = true;
    this.isModalBtnDeleteDisabled = true;
  }
  async submit(): Promise<void> {
    try {
      const entityId = this.$props.entity.id;

      //Apply Uploading image
      if (this.imageModalSrc && this.imageModalSrc != this.imageDefault && this.imageModalLoadedSuccessfully) {
        const formData = new FormData();
        const file = (this.$refs.fileInput as any).files[0];
        formData.append("image", file);
        await this.$services.center.uploadImage(entityId, formData);
      }

      //Apply Deleting image
      if (this.imageModalSrc && this.imageModalSrc == this.imageDefault && !this.imageModalLoadedSuccessfully) {
        await this.$services.center.deleteImage(entityId);
      }
      Vue.swal({
        icon: "success",
        title: this.$i18n.t("entity.change-image.upload_success_message"),
        showConfirmButton: false,
        timer: 2000,
      });
      this.showModal = false;
      this.$router.go(0);
    } catch (err) {
      Vue.swal({
        icon: "error",
        title: this.$i18n.t("entity.change-image.upload_error_message"),
        showConfirmButton: false,
        timer: 2500,
      });
    }
  }
}

export default CenterHeader;
