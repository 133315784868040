
import { Component, Prop, Vue } from "vue-property-decorator";
import { PModal, PButton } from "@/common/components";
import { PRow } from "@/common/components/look/PRow";
import { PCol } from "@/common/components/look/PCol";

@Component({
  name: "custom-modal",
  components: {
    PRow,
    PCol,
    PModal,
    PButton,
  },
})
export class CustomModal extends Vue {
  @Prop({ required: false, default: true }) show!: boolean;
  @Prop({ required: false, default: 400 }) width!: number;
  @Prop({ required: false, default: 330 }) height!: number;
  @Prop({ required: false, default: "primary" }) variant!: string;
  @Prop({ required: false, default: "CashToday Lógicas" }) title!: string;

  close(): void {
    this.$emit("close");
  }
}
export default CustomModal;
