
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Country, Currency, Delegation, MachineType, User } from "@/entities";
import { SelectType } from "@/common/components";
import { Solution } from "@/components/machines/machine-new-modal/MachineNewModal.types";
import { customDebounce } from "@/common/utils";
import VueMethods from "@/vue-methods";

interface Filter {
  field: string;
  operator: string;
  value: string;
}
interface Query extends Record<string, unknown> {
  limit: number;
  offset: number;
  sortField: string;
  sortWay: string;
  filters: Filter[];
}

@Component({
  components: {},
  mixins: [VueMethods],
})
export class MachineForm extends Vue {
  @Prop({ required: true }) value!: Solution;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.solution = JSON.parse(JSON.stringify(this.value));
  }
  @Watch("selectedDelegationId", { immediate: true, deep: true })
  onChangeValueDelegation(): void {
    const delegation = this.delegations.find((d) => d.id === this.selectedDelegationId);
    if (delegation) {
      this.solution.delegationCode = delegation.code;
      this.solution.delegationName = delegation.name;
    }
  }
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Prop({ required: false }) textCancelName!: string;
  @Getter("getCountries") getCountries!: Country[];
  @Getter("getLoggedUser") getLoggedUser!: User;

  countries: SelectType[] = [];
  delegations: SelectType[] = [];
  deviceTypes: SelectType[] = [];
  currenciesSelected: SelectType[] = [];
  selectedDelegationId = "";
  solution: Solution = new Solution();
  validado = false;
  selectedCountry = "";

  async mounted(): Promise<void> {
    this.getCountries.forEach((it: Country) =>
      this.countries.push({ text: it.name.toString(), value: it.isoCode.toString() })
    );
    if (!this.getLoggedUser.corporateAdmin) {
      this.selectedCountry = this.getLoggedUser.country;
      this.solution.countryCode = this.selectedCountry;
    }

    const machineTypes: MachineType[] = await this.$services.machine.fetchTypes();
    machineTypes.forEach((it: MachineType) =>
      this.deviceTypes.push({ text: this.$t("deviceTypes." + it.name) as string, value: it.name })
    );

    const currencies: Currency[] = await this.$services.currency.fetchCurrencies();
    currencies.forEach((it: Currency) => this.currenciesSelected.push({ text: it.isoCode, value: it.isoCode }));
  }

  searchWithDebounceDelegation(searchText = ""): void {
    const debounceDuration = searchText.trim() === "" ? 0 : 500;
    customDebounce(() => {
      if (searchText.trim() === "") {
        this.getDelegations();
      } else {
        this.getDelegations(searchText);
      }
    }, debounceDuration);
  }

  changeSelectedCountry(country = ""): void {
    this.selectedCountry = country;
    this.solution.countryCode = this.selectedCountry;
    this.getDelegations();
  }

  hasSelectedCountry(): boolean {
    return this.selectedCountry.length > 0;
  }

  async getDelegations(searchText = ""): Promise<void> {
    if (!this.selectedCountry || searchText.length > 3 || searchText.length === 1 || searchText.length === 2) return;
    const countryCode = this.selectedCountry;
    const query: Query = {
      limit: 100,
      offset: 0,
      sortField: "code",
      sortWay: "ASC",
      filters: [],
    };
    query["filters"] = [{ field: "delegationCountryCode", operator: "FULL_TEXT_SEARCH", value: countryCode }];
    if (searchText.match(/^\w+$/i) && searchText.length > 0) {
      query["filters"].push({ field: "delegationCode", operator: "FULL_TEXT_SEARCH", value: searchText });
    }
    const response = await this.$services.machine.fetchDelegationSearch(query);
    const delegations = response.results;
    this.setDelegations(delegations);
  }

  setDelegations(delegations: Delegation[]): void {
    this.delegations = [];
    delegations.forEach((it: Delegation) => {
      if (it.code.length === 3) {
        this.delegations.push({
          text: it.code + " - " + it.name,
          value: it.id,
          id: it.id,
          code: it.code,
          name: it.name,
        });
      }
    });
  }

  cancel(): void {
    this.$emit("cancel");
  }

  action(): void {
    this.$emit("action", this.solution);
  }
}

export default MachineForm;
