import { ServicePointServiceMock } from "./servicepoint.types";

export const mockServicePointService = (): ServicePointServiceMock => ({
  fetchServicePoints: jest.fn(),
  fetchServicePointsByGroupRules: jest.fn(),
  createServicePoint: jest.fn(),
  createServicePointAndDevice: jest.fn(),
  updateServicePoint: jest.fn(),
  updateDeviceServicePoint: jest.fn(),
  changeStatus: jest.fn(),
  fetchServicePointTypes: jest.fn(),
});
