import { Center } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import { ResponseCreate } from "@/services";

export interface CenterService {
  fetchCenters(map: Record<string, unknown>): Promise<Pageable<Center>>;
  fetchCentersV2(map: Record<string, unknown>): Promise<Pageable<Center>>;
  fetchCentersByEntityId(entityIs: string, map: Record<string, unknown>): Promise<Pageable<Center>>;
  uploadImage(entityId: string, image: FormData): Promise<void>;
  deleteImage(entityId: string): Promise<void>;
  createCenter(center: Center): Promise<ResponseCreate>;
  updateCenter(center: Center): Promise<void>;
  changeStatus(center: Center): Promise<void>;
}

export interface ICenterFilter {
  name?: string;
  code: string;
  refresh: boolean;
  active: boolean;
}

export const iniCenterFilter: ICenterFilter = {
  name: "",
  code: "",
  refresh: false,
  active: true,
};

export interface CenterServiceMock {
  fetchCenters: jest.Mock<Promise<Pageable<Center>>>;
  fetchCentersV2: jest.Mock<Promise<Pageable<Center>>>;
  fetchCentersByEntityId: jest.Mock<Promise<Pageable<Center>>>;
  uploadImage: jest.Mock<Promise<void>>;
  deleteImage: jest.Mock<Promise<void>>;
  createCenter: jest.Mock<Promise<ResponseCreate>>;
  updateCenter: jest.Mock<Promise<void>>;
  changeStatus: jest.Mock<Promise<void>>;
}
