
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import PButton from "../PButton/PButton.vue";
import { PaginationPTableType } from "..";
import { translations, TranslationsPaginationPTable } from "./paginationPTableLanguages";

@Component({
  name: "pagination",
  components: {
    PIcon,
    PButton,
  },
})
export class PPagination extends Vue {
  @Prop({required: false, default: "es"}) language!: string;
  @Prop({required: false}) itemName!: string;
  @Prop({required: false}) translation!: TranslationsPaginationPTable;
  @Prop({required: true}) value!: PaginationPTableType;
  @Prop({required: false}) maxPageSize!: number;
  @Prop({required: false, default: false}) readonlyPageSize!: boolean;

  @Watch("value", {immediate: true})
  onChangeValue(): void {
    this.valueLocal = Object.assign({}, this.valueLocal, this.value);
    this.pageSize = this.valueLocal.pageSize;
    this.currentPage = this.valueLocal.currentPage;
  }

  @Watch("value.totalElements", {immediate: true})
  onChangeTotalElement(): void {
    this.valueLocal.totalElements = this.setupValueTotalElements();
  }

  valueLocal: PaginationPTableType = {
    pageSize: 20,
    totalElements: 0,
    currentPage: 1,
  };
  currentPage: number = this.valueLocal.currentPage;
  translator: TranslationsPaginationPTable = translations[0].translations;
  pageSize: number = this.valueLocal.pageSize;

  constructor() {
    super();
  }

  created(): void {
    const languaje = translations.find((tran) => tran.language === this.language)?.translations;
    if (languaje) {
      this.translator = languaje;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
  }

  setupValueTotalElements(): number {
    return this.value.totalElements ? this.value.totalElements : 0;
  }

  get totalElements(): number {
    return this.valueLocal.totalElements
  }

  get pageMin(): number {
    return this.currentStartingElementCount;
  }

  get pageMax(): number {
    return Math.min(this.pageMin + this.valueLocal.pageSize - 1, this.valueLocal.totalElements);
  }

  get lastPageNumber(): number {
    return this.valueLocal.totalElements == 0 ? 1 : Math.ceil(this.valueLocal.totalElements / this.valueLocal.pageSize);
  }

  get currentStartingElementCount(): number {
    if (this.valueLocal.totalElements == 0) return 0;
    return this.valueLocal.currentPage == 1 ? 1 : (this.valueLocal.currentPage - 1) * this.pageSize + 1;
  }

  get currentPageMax(): number {
    let potentialMax = this.valueLocal.pageSize * this.currentPage;
    return Math.min(potentialMax, this.valueLocal.totalElements);
  }

  get currentPageMin(): number {
    return this.valueLocal.pageSize * (this.currentPage - 1) + 1;
  }

  get isFirstPageNumber(): boolean {
    return this.currentPage === 1;
  }

  get isLastPageNumber(): boolean {
    return this.currentPage === this.lastPageNumber;
  }

  get hasDefaultPageSize(): boolean {
    return this.valueLocal.pageSizeDefault != null && this.valueLocal.pageSizeDefault > 0;
  }

  get isDefaultPageSize(): boolean {
    return this.valueLocal.pageSizeDefault != null && this.pageSize == this.valueLocal.pageSizeDefault;
  }

  setDefaultPageSize(): void {
    if (this.valueLocal.pageSize) {
      this.pageSize = this.valueLocal.pageSize;
      this.updatePageSize();
    }
  }

  updatePageSize(): void {
    if (this.pageSize && this.pageSize > 0) {
      this.valueLocal.pageSize = Math.ceil(this.pageSize);
      this.valueLocal.currentPage = 1;
      this.emitChanges();
    } else {
      this.pageSize = this.valueLocal.pageSize;
    }
  }

  validateMax():void{
    if(this.maxPageSize && this.pageSize > this.maxPageSize) this.pageSize = this.maxPageSize;
  }

  updateCurrentPage(): void {
    if (this.currentPage && this.currentPage > 0 && this.currentPage <= this.lastPageNumber) {
      this.valueLocal.currentPage = this.currentPage;
      this.emitChanges();
    } else {
      this.currentPage = this.valueLocal.currentPage;
    }
  }

  emitChanges(): void {
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }

  onClickDirection(factor: number): void {
    if (factor === 0) {
      this.currentPage = 1;
    } else if (factor === this.lastPageNumber) {
      this.currentPage = this.lastPageNumber;
    } else if (factor > 0) {
      this.currentPage++;
    } else if (factor < 0) {
      this.currentPage--;
    }
    this.updateCurrentPage();
  }
}

export default PPagination;
