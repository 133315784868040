
import { Component, Vue } from "vue-property-decorator";
import { PDropDownContainer } from "@/common/components";
import { PRow } from "@/common/components/look/PRow";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import SupportDropDownDeviceId from "./SupportDropDownDeviceId.vue";

@Component({
  name: "support-device-id-filter",
  components: {
    PRow,
    PIcon,
    PDropDownContainer,
    SupportDropDownDeviceId,
  },
})
export default class SupportDeviceIdFilter extends Vue {}
