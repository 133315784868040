import { Country, IStaticData, User } from "@/entities";
import { IMutations } from "@/store";
import { IState } from "../store.types";

export const mutations: IMutations = {
  // Root
  SET_LOCALE(state: IState, payload: string): void {
    state.locale = payload;
  },
  SET_URL(state: IState, payload: string): void {
    state.url = payload;
  },
  SET_IS_SIGNED_IN(state: IState, payload: boolean): void {
    state.isSignedIn = payload;
  },
  SET_LOGGED_USER(state: IState, payload: User): void {
    state.loggedUser = payload;
  },

  // Country
  SET_COUNTRIES(state: IState, payload: Country[]): void {
    state.countries = payload;
  },

  SET_STATIC_DATA(state: IState, payload: IStaticData): void {
    state.staticData = payload;
  },
};
