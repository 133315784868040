import Vue from "vue";
import { UserGroupService } from "./user_group.types";
import { BACKEND_BASE_URL } from "../backend.types";

export class UserGroupServiceImpl implements UserGroupService {
  async addMembers(members: string[], groupId: string): Promise<void> {
    await Vue.$axios.put(`${BACKEND_BASE_URL}/api/v1/group/${groupId}/users`, members);
  }

  async removeMembers(members: string[], groupId: string): Promise<void> {
    const headers = {};
    await Vue.$axios.delete(`${BACKEND_BASE_URL}/api/v1/group/${groupId}/users`, { headers: headers, data: members });
  }
}
