
import { Component, Vue } from "vue-property-decorator";
import { Entity, iniRawEntity } from "@/entities";
import EntityForm from "@/components/entities/entity-form/EntityForm.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import EntityModal from "@/components/entities/entity-modal/EntityModal.vue";
import Wizard from "@/components/commons/Wizard.vue";

@Component({
  name: "entity-header",
  components: {
    EntityForm,
    EntityModal,
    PIcon,
    Wizard,
  },
})
export class EntityHeader extends Vue {
  entity: Entity = new Entity({ ...iniRawEntity });
  showModal = false;

  openModal(): void {
    this.entity = new Entity({ ...iniRawEntity });
    this.showModal = true;
  }

  refresh(): void {
    this.$emit("refresh");
  }
}
export default EntityHeader;
