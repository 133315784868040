
import { iniPaginationPTable, PaginationPTableType, PTableSimple } from "@/common/components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { LocalPagination, TitleSizePage, NoData, Utilities } from "@/components/commons";
import { Filter, FilterOperator, PageRequest } from "@/services";
import { SolutionFileHistoryReportFields } from "../solution-history-grid/SolutionFileHistoryReportFields";
import { IField, SolutionHistory } from "@/entities";
import { i18n } from "@/i18n";
import { DateTimeFormatter, Instant, LocalDateTime } from "@js-joda/core";
import VueFilters from "@/vue-filters";
import DateTimeMixin from "@/mixins/datetime";
import { customDebounce } from "@/common/utils";
import { PRow } from "@/common/components/look/PRow";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PCol } from "@/common/components/look/PCol";

@Component({
  name: "solution-history-grid",
  components: {
    PRow,
    PCol,
    PIcon,
    PTableSimple,
    LocalPagination,
    TitleSizePage,
    NoData,
  },
  mixins: [VueFilters, DateTimeMixin],
})
export class SolutionHistoryGrid extends Vue {
  @Prop({ required: true }) filters!: any;
  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.mapFilterToRecord();
  }
  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    if (this.firstLoad) {
      this.getSolutions();
      this.firstLoad = false;
    } else {
      customDebounce(() => this.getSolutions(), 350);
    }
  }
  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortWay != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortField != this.sort.sortField
    ) {
      this.paginationBack.sortWay = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortField = this.sort.sortField;
      this.updateParams();
    }
  }
  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  translateStatus(status: string): string {
    if (status === "Uploaded") return this.$t("support_panel.dropdown.types.uploaded") as string;
    else if (status === "Processed") return this.$t("support_panel.dropdown.types.processed") as string;
    else return this.$t("support_panel.dropdown.types.errors") as string;
  }

  mapFilterToRecord(): void {
    this.filters_field = [];
    if (!Utilities.isObjectEmpty(this.filters)) {
      Object.keys(this.filters).forEach((key: any) => {
        if (!Utilities.isObjectEmpty(this.filters[key]) && this.mapFilters[key]) {
          this.filters_field.push({
            field: this.mapFilters[key],
            operator: FilterOperator.FULL_TEXT_SEARCH,
            value: this.filters[key].constructor === Array ? this.filters[key].join() : this.filters[key],
          });
        }
      });
      const dStart = LocalDateTime.ofInstant(Instant.parse(this.filters.startDate.toISOString()));
      const dEnd = LocalDateTime.ofInstant(Instant.parse(this.filters.endDate.toISOString()));
      const formatter = DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss");
      this.filters_field.push({
        field: "creationDate",
        operator: FilterOperator.BETWEEN,
        value: dStart.format(formatter) + "," + dEnd.format(formatter),
      });
    }
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }
  firstLoad = true;
  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: true,
    sortField: "creationDate",
  };
  filters_field: Record<string, unknown>[] = [];
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = Utilities.pageable(
    this.pagination.pageSize,
    null,
    this.sort.sortField,
    this.sort.sortDesc ? "DESC" : "ASC"
  );
  mapFilters: Record<string, string> = {
    text: "filename",
    type: "status",
    country: "country",
  };
  solutions: SolutionHistory[] = [];
  paginationBack: PageRequest = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    sortField: this.params["sortField"] as string,
    sortWay: this.params["sortWay"] as string,
    filters: [],
  };

  paginationDefault = {
    limit: 100,
  };

  updateParams(): void {
    this.params = Utilities.pageable(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.paginationBack.sortField,
      this.paginationBack.sortWay,
      this.filters_field
    );
  }

  async getSolutions(): Promise<void> {
    const data = await this.$services.solutionHistory.fetchSolutionFileHistory(this.params);
    this.solutions = data.results;
    this.pagination.totalElements = data.totalResult;
  }

  get fields(): IField[] {
    return [
      {
        key: "filename",
        label: this.$t("support_panel.table.filename") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "country",
        label: this.$t("support_panel.table.country") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "status",
        label: this.$t("support_panel.table.status") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "requestDate",
        label: this.$t("support_panel.table.requestDate") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "creationDate",
        label: this.$t("support_panel.table.creationDate") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "actions",
        label: this.$t("support_panel.table.download") as string,
        sortable: false,
        thClass: "table-header",
        tdClass: "table-cell table-cell-download",
        visible: true,
        aux: true,
      },
    ];
  }

  async getSolutionHistoryReport(id: string): Promise<void> {
    const sortField: [] = [];
    const filters: Filter[] = [
      {
        field: "solutionFileHistoryId",
        operator: FilterOperator.CONTAINS,
        value: id,
      },
    ];
    const fields = SolutionFileHistoryReportFields.fields;
    await this.$services.solutionHistory.downloadSolutionFileHistory(sortField, filters, fields).then((file: File) => {
      const aElement = document.createElement("a");
      const url = URL.createObjectURL(file);
      aElement.href = url;
      aElement.download = file.name;
      aElement.click();
      window.URL.revokeObjectURL(url);
    });
  }
}

export default SolutionHistoryGrid;
