
import VueFilters from "@/vue-filters";
import { BvTableFieldArray } from "bootstrap-vue";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Device, ServicePoint } from "@/entities";

@Component({
  name: "device-detail",
  mixins: [VueFilters],
})
export default class DeviceDetail extends Vue {
  @Prop({ required: true }) value!: Device;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  valueLocal: ServicePoint[] = [];

  get fields(): BvTableFieldArray {
    return [
      {
        key: "device.code",
        label: this.$t("spPage.table.detail.machine_id") as string,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "device.name",
        label: this.$t("spPage.table.detail.machine_name") as string,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "device.type",
        label: this.$t("spPage.table.detail.type_device") as string,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "device.manufacturer",
        label: this.$t("spPage.table.detail.producer") as string,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "device.model",
        label: this.$t("spPage.table.detail.model") as string,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "device.ip",
        label: this.$t("spPage.table.detail.ip") as string,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "device.port",
        label: this.$t("spPage.table.detail.port") as string,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      {
        key: "actions",
        label: "",
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
    ];
  }
  constructor() {
    super();
  }

  openModalEdit(): void {
    this.$emit("action", { action: "edit-device", data: this.valueLocal });
  }
}
