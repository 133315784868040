
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelectType } from "@/common/components";
import PFormSelectNarrow from "./PFormSelectNarrow.vue";
import PFormCheckBox from "../PFormCheckBox/PFormCheckBox.vue";
import PButton from "../PButton/PButton.vue";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  components: {
    PFormCheckBox,
    PFormSelectNarrow,
    PDropDownContainer,
    PButton,
    PIcon,
  },
})
export class PFormSelectMultiFilter extends Vue {
  @Prop({ required: true }) value!: string[];
  @Watch("value", { deep: true, immediate: true })
  onChangeValue() {
    this.rellenarValueTempInicial();
    this.rellenarviewInput();
  }
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: true }) filterProperties!: SelectType[];
  @Prop({ required: false, default: undefined }) state!: boolean;
  @Prop({ required: false, default: undefined }) invalid_feedback!: string;
  @Prop({ required: false, default: "Sin filtros" }) textNofilter!: string;
  @Prop({ required: false, default: "más" }) textMore!: string;
  @Prop({ required: false, default: "Limpiar" }) textButtonClear!: string;
  @Prop({ required: false, default: "Aplicar" }) textButtonApply!: string;
  @Prop({ required: false, default: false }) isExtended!: boolean;
  @Prop({ required: false, default: true }) canShowTitleFilter!: boolean;
  pussOption = false;
  @Watch("pussOption")
  onChangePussOption() {
    if (this.pussOption) {
      this.rellenarValueTempInicial();
      this.rellenarviewInput();
    }
  }
  selectItemFilterPropertie = "";
  allCheck = false;
  valueTemp: string[] = [];
  viewInput = { firstSelectedText: "", more: "" };
  filterOptions = "";
  mounted(): void {
    if (this.filterProperties.length > 0) {
      this.selectItemFilterPropertie = this.filterProperties[0].value;
    }
  }

  get placeholderSearch(): string {
    const filterOn = this.filterProperties.find((filter) => filter.value === this.selectItemFilterPropertie);
    if (filterOn) {
      return "Buscar por " + filterOn.text;
    } else {
      return "Seleccione porque buscar";
    }
  }
  get optionsComp(): SelectType[] {
    return this.options.filter((op) => op.text.toUpperCase().includes(this.filterOptions.toUpperCase()));
  }
  changeOptions(): void {
    if (this.valueTemp.find((v) => v != "")) {
      this.allCheck = false;
    }
  }
  rellenarValueTempInicial(): void {
    this.options.forEach((op, index) => {
      if (this.value.findIndex((val) => val === op.value) > -1) {
        this.$set(this.valueTemp, index, op.value); //es porque sino no esreactivo el valor
      } else {
        this.$set(this.valueTemp, index, "");
      }
    });
    if (this.options.length == this.value.length && this.value.filter((v) => v != "").length > 0) {
      this.allCheck = true;
    }
  }
  rellenarviewInput(): void {
    this.viewInput.more = "";
    this.viewInput.firstSelectedText = "Sin filtros";
    const valueTempRelleno = this.valueTemp.filter((val) => val != "");
    if (valueTempRelleno.length > 0) {
      const optionsRellenos = this.options.find((op) => op.value == valueTempRelleno[0]);

      this.viewInput.firstSelectedText = optionsRellenos ? " " + optionsRellenos.text : this.textNofilter + "";
    }
    if (valueTempRelleno.length > 1) {
      this.viewInput.more = " +" + (valueTempRelleno.length - 1) + " " + this.textMore;
    }
  }
  search(str: string): void {
    const index = this.options.findIndex((op) => op.value == str);
    if (index > -1) {
      this.$set(this.valueTemp, index, this.options[index].value);
    } else {
      console.error("Options de PFormSelectMultiFilter no coinciden con los del _PFormSelectMultiFilter ");
    }
  }
  mostrarOptions(): void {
    this.pussOption = true;
  }
  aplicar(): void {
    this.pussOption = false;
    this.rellenarviewInput();
    this.$emit("input", [...this.valueTemp]);
  }
  borrarSeleccion(): void {
    this.options.forEach((op, index) => {
      this.$set(this.valueTemp, index, "");
    });
    this.allCheck = false;
  }
  seleccionarTodos(): void {
    if (this.allCheck) {
      this.optionsComp.forEach((op, index) => {
        this.$set(this.valueTemp, index, op.value);
      });
    } else {
      this.optionsComp.forEach((op, index) => {
        this.$set(this.valueTemp, index, "");
      });
    }
  }
}
export default PFormSelectMultiFilter;
