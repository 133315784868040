
import { Component, Prop, Vue } from "vue-property-decorator";
import { CenterTabs } from "@/components/centers";
import { PFormInputText } from "@/common/components";
import { RawGroup } from "@/entities";
import { PRow } from "@/common/components/look/PRow";
import { PCol } from "@/common/components/look/PCol";

@Component({
  name: "group-header",
  components: {
    PRow,
    PCol,
    CenterTabs,
    PFormInputText,
  },
})
export class GroupHeader extends Vue {
  @Prop({ required: true }) group!: RawGroup;

  goToUsers(): void {
    this.$router.push("/users");
  }
}
export default GroupHeader;
