
import { Component, Vue } from "vue-property-decorator";
import { i18n } from "@/i18n";
import {
  PButton,
  PFormInputText,
  PFormSelect,
  PFormSelectMultiSearch,
  PFormSelectSearch,
  PModal,
  SelectType,
} from "@/common/components";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { Getter } from "vuex-class";
import { Country, Currency, MachineType, RawDelegation } from "@/entities";
import { Solution } from "./MachineNewModal.types";
import MachineForm from "@/components/machines/machine-form/MachineForm.vue";

@Component({
  name: "machine-new-modal",
  i18n,
  components: {
    PIcon,
    PModal,
    PFormInputText,
    PButton,
    PFormSelect,
    PFormSelectMultiSearch,
    PFormSelectSearch,
    MachineForm,
  },
})
export class MachineNewModal extends Vue {
  @Getter("getCountries") getCountries!: Country[];
  countries: SelectType[] = [];
  delegations: SelectType[] = [];
  deviceTypes: SelectType[] = [];
  currenciesSelected: SelectType[] = [];
  showModal = false;
  solution: Solution = new Solution();

  async created(): Promise<void> {
    this.getCountries.forEach((it: Country) =>
      this.countries.push({ text: it.name.toString(), value: it.isoCode.toString() })
    );
    const delegations: RawDelegation[] = await this.$services.machine.fetchDelegations();
    delegations.forEach((it: RawDelegation) =>
      this.delegations.push({ text: it.name + " - " + it.code, value: it.code })
    );
    const machineTypes: MachineType[] = await this.$services.machine.fetchTypes();
    machineTypes.forEach((it: MachineType) =>
      this.deviceTypes.push({ text: this.$t("deviceTypes." + it.name) as string, value: it.name })
    );

    const currencies: Currency[] = await this.$services.currency.fetchCurrencies();
    currencies.forEach((it: Currency) => this.currenciesSelected.push({ text: it.isoCode, value: it.isoCode }));
  }

  openModal(): void {
    this.solution = new Solution();
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
    location.reload();
  }

  async submit(data: Solution): Promise<void> {
    try {
      await this.$services.solution.createSolution(data);
      Vue.swal({
        icon: "success",
        title: this.$i18n.t("machine.machine-new-modal.success_message"),
        showConfirmButton: false,
        timer: 2000,
      });
      this.closeModal();
      this.$emit("action");
      return;
    } catch (err) {
      console.log(err);
      Vue.swal({
        icon: "error",
        title: this.$i18n.t("machine.machine-new-modal.error_message"),
        showConfirmButton: false,
        timer: 2500,
      });
    }
  }
}
export default MachineNewModal;
