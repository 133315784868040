import { RawLimit } from "@/entities/limit";

export type RawEntity = {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly imageLink: string;
  readonly centerCount: number;
  readonly servicePointCount: number;
  readonly deviceCount: number;
  readonly customerCount: number;
  readonly countryCode: string;
  readonly active: boolean;
  readonly currencyLimits: RawLimit[];
};

export const iniRawEntity: RawEntity = {
  id: "",
  name: "",
  code: "",
  imageLink: "",
  centerCount: 0,
  servicePointCount: 0,
  deviceCount: 0,
  customerCount: 0,
  countryCode: "",
  active: true,
  currencyLimits: [],
};
