import { Delegation, Limit, RawCenter } from "@/entities";

export class Center {
  id: string;
  entityId: string;
  code: string;
  name: string;
  countryCode: string;
  provinceName: string;
  cityName: string;
  zipCode: string;
  delegation: Delegation | null;
  delegationId: string;
  limits: Limit[];
  active: boolean;
  servicePointsCount: number;

  constructor(data: RawCenter) {
    this.id = data.id || "";
    this.code = data.code || "";
    this.name = data.name || "";
    this.countryCode = data.countryCode || "";
    this.provinceName = data.provinceName || "";
    this.cityName = data.cityName || "";
    this.zipCode = data.zipCode || "";
    this.limits = data.currencyLimits ? data.currencyLimits.map((value) => new Limit(value)) : [];
    this.entityId = data.entityId;
    this.delegation = data.delegation ? new Delegation(data.delegation) : null;
    this.delegationId = data.delegation ? data.delegation.id : "";
    this.active = data.active || false;
    this.servicePointsCount = data.serviceCount || 0;
  }
}
