
import { Entity, IField, iniRawEntity } from "@/entities";
import { iniPaginationPTable, PaginationPTableType, PTableSimple } from "@/common/components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PRow } from "@/common/components/look/PRow";
import { PCol } from "@/common/components/look/PCol";
import { LocalPagination, TitleSizePage, Utilities } from "@/components/commons";
import { IEntityFilter, PageRequest, SearchEntitiesRequest } from "@/services";
import { i18n } from "@/i18n";
import EntityGridOptions from "@/components/entities/entity-grid-options/EntityGridOptions.vue";
import EntityForm from "@/components/entities/entity-form/EntityForm.vue";
import EntityModal from "@/components/entities/entity-modal/EntityModal.vue";

@Component({
  name: "entity-grid",
  components: {
    PRow,
    PCol,
    PTableSimple,
    LocalPagination,
    TitleSizePage,
    EntityGridOptions,
    EntityForm,
    EntityModal,
  },
})
export class EntityGrid extends Vue {
  @Prop({ required: true }) filters!: IEntityFilter;

  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.updateParams();
  }

  @Watch("requestParams", { immediate: true, deep: true })
  onChangeParams(): void {
    let counterValidFilters = 0;
    const mapParams: Record<string, any> = this.requestParams;

    for (const key in mapParams) {
      if (!Utilities.isObjectEmpty(mapParams[key])) {
        counterValidFilters++;
      }
    }

    if (counterValidFilters > this.minRequestParams) {
      this.isMinRequestParamsMet = true;
      this.fetch();
    }
  }

  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortWay != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortField != this.sort.sortField
    ) {
      this.paginationBack.sortWay = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortField = this.sort.sortField;
      this.updateParams();
    }
  }

  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: false,
    sortField: "aggregateId",
  };
  pagination: PaginationPTableType = { ...iniPaginationPTable };

  isMinRequestParamsMet = false;
  minRequestParams = 4;
  requestParams: SearchEntitiesRequest = {
    limit: this.pagination.pageSize,
    offset: 0,
    sortField: this.sort.sortField,
    sortWay: this.sort.sortDesc ? "DESC" : "ASC",
  };
  paginationBack: PageRequest = {
    limit: this.requestParams.limit,
    offset: this.requestParams.offset,
    sortField: this.requestParams.sortField,
    sortWay: this.requestParams.sortWay,
    filters: [],
  };
  entities: Entity[] = [];
  imageDefault = require("@/assets/images/imago-prosegur@3x.png");
  paginationDefault = {
    limit: 100,
  };
  selectedEntity: Entity = new Entity({ ...iniRawEntity });
  showEditModal = false;

  updateParams(): void {
    this.requestParams = {
      codeOrName: this.filters.codeOrName,
      countryCode: this.filters.country,
      limit: this.paginationBack.limit,
      offset: this.paginationBack.offset,
      sortField: this.paginationBack.sortField,
      sortWay: this.paginationBack.sortWay,
    };
    if (!this.filters.active) {
      this.requestParams.active = false;
    }
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  async fetch(): Promise<void> {
    const data = await this.$services.entity.fetchEntitiesV2(this.requestParams);
    this.entities = data.results;
    this.pagination.totalElements = data.totalResult;
  }

  changeActive(entity: Entity): void {
    Vue.swal({
      icon: "question",
      customClass: {
        confirmButton: "swal2_prosegur_confirm",
        cancelButton: "swal2_prosegur_cancel",
      },
      showCancelButton: true,
      cancelButtonText: this.$t("users.user_alert.cancel-button") as string,
      html: entity.active
        ? this.$t("entity.table.activeMessage", { name: entity.name })
        : this.$t("entity.table.inactiveMessage", { name: entity.name }),
    }).then(async (response) => {
      if (response.isConfirmed) {
        await this.$services.entity
          .changeStatus(entity)
          .then(() => {
            Vue.swal({
              icon: "success",
              title: this.$i18n.t("entity.modal.success"),
              showConfirmButton: false,
              timer: 2000,
            });
            this.fetch();
          })
          .catch(() => this.fetch());
      } else {
        entity.active = !entity.active;
      }
    });
  }

  get fields(): IField[] {
    return [
      {
        key: "name",
        label: this.$t("entity.table.entity") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell-first table-cell",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "code",
        label: this.$t("entity.table.code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "center",
        label: this.$t("entity.table.centers") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "service_point",
        label: this.$t("entity.table.servicePoints") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "machine",
        label: this.$t("entity.table.machines") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "user",
        label: this.$t("entity.table.users") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "countryCode",
        label: this.$t("machine.table.country") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "active",
        label: this.$t("entity.table.active") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "actions",
        label: "",
        sortable: false,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-popup",
        visible: true,
        aux: true,
        code: "",
      },
    ];
  }

  goToEntity(row: Entity): void {
    this.$router.push({ name: "entity-centers", params: { id: row.id } });
  }

  imageUrlAlt(event: any) {
    event.target.src = this.imageDefault;
  }

  action(data: { action: string; data: Entity }): void {
    if (data.action === "edit") {
      this.openEditModal(data.data);
    }
  }

  openEditModal(entity: Entity): void {
    this.selectedEntity = entity;
    this.showEditModal = true;
  }
}

export default EntityGrid;
