
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Center,
  Currency,
  Entity,
  iniRawServicePoint,
  iniRawServicePointType,
  ServicePoint,
  ServicepointType,
} from "@/entities";
import VisualizationToggle from "@/components/groups/group-permissions-tab/visualization-toggle/VisualizationToggle.vue";
import PCardShowHiden from "@/common/components/look/PCard/PCardShowHiden.vue";
import PButton from "@/common/components/look/PButton/PButton.vue";
import { iniRawLimit, Limit } from "@/entities/limit";
import { Getter } from "vuex-class";
import { SelectType } from "@/common/components";
import { SERVICEPOINTTYPE_CASHTODAY } from "@/services";

@Component({
  components: {
    PButton,
    VisualizationToggle,
    PCardShowHiden,
  },
})
export class ServicePointForm extends Vue {
  @Prop({ required: true }) value!: ServicePoint;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.servicePoint = { ...this.value };
    this.servicePoint.centerId = this.center.id;
    this.servicePoint.entityId = this.entity.id;
    if (this.servicePoint.servicePointType) {
      this.servicePointTypeSelected = { ...this.servicePoint.servicePointType };
    } else {
      this.servicePointTypeSelected = new ServicepointType({ ...iniRawServicePointType });
    }
    this.limits = [...this.value.limits];
  }
  @Prop({ required: true }) center!: Center;
  @Prop({ required: true }) entity!: Entity;
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Prop({ required: false }) textCancelName!: string;
  @Prop({ required: false, default: true }) isModify!: boolean;
  @Getter("getCurrencies") getCurrencies!: Currency[];
  showModal = false;
  isCashToday = SERVICEPOINTTYPE_CASHTODAY;

  servicePoint: ServicePoint = new ServicePoint({ ...iniRawServicePoint });
  validado = false;
  limits: Limit[] = [];
  typeOptions: SelectType[] = [];
  servicePointTypeSelected: ServicepointType = new ServicepointType({ ...iniRawServicePointType });

  mounted(): void {
    this.getTypes();
  }

  cancel(): void {
    this.$emit("cancel");
  }

  getRules(): string {
    return this.servicePointTypeSelected.id === this.isCashToday
      ? "required|alpha_num_cash_today_code"
      : "required|alpha_num_non_cash_today_code";
  }

  action(action: string): void {
    this.servicePoint.limits = [...this.limits];
    this.servicePoint.servicePointType = { ...this.servicePointTypeSelected };
    this.$emit("action", this.servicePoint, this.isModify, action);
  }

  addLimit(): void {
    this.limits.push(new Limit(iniRawLimit));
  }

  getOptionsAvailables(limit: Limit): SelectType[] {
    const options = JSON.parse(JSON.stringify(this.getCurrenciesOptions));
    if (limit.currency != "") {
      options.push({ value: limit.currency, text: limit.currency });
    }
    return options.sort((a: SelectType, b: SelectType) => {
      if (a.value < b.value) {
        return -1;
      } else if (a.value > b.value) {
        return 1;
      } else return 0;
    });
  }

  get getCurrenciesOptions(): SelectType[] {
    return this.getCurrencies
      .filter((c) => !this.limits.map((value1) => value1.currency).includes(c.isoCode))
      .map((currency) => {
        return { value: currency.isoCode, text: currency.isoCode };
      });
  }

  removeLimit(index: number): void {
    this.limits.splice(index, 1);
  }

  getTypes(): void {
    this.$services.servicepoint.fetchServicePointTypes().then((response) => {
      this.typeOptions = response.map((del) => {
        return { value: del.id, text: del.name };
      });
    });
  }

  get hasCurrencies(): boolean {
    return this.limits.length < this.getCurrencies.length;
  }
}

export default ServicePointForm;
