import { RawDeviceAlert } from "./deviceAlert.types";

export class DeviceAlert {
  readonly aggregateId?: string;
  readonly currency: string | null;
  readonly value: number;
  readonly recipients: string;
  readonly alertType: string;

  constructor(data: RawDeviceAlert) {
    this.aggregateId = data.aggregateId;
    this.currency = data.currency;
    this.value = data.value;
    this.recipients = data.recipients;
    this.alertType = data.alertType;
  }
}
