
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "support-tabs",
  components: {},
})
export class SupportTabs extends Vue {
  titleCashToday = this.$t("support_panel.tab.title.incidents");
  titleRegisterSolution = this.$t("support_panel.tab.title.massive_operations");

  cashGridShowContent(value: string): void {
    this.titleCashToday = "Cash Today (" + value + ")";
  }
}
export default SupportTabs;
