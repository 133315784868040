
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import {Placement} from "popper.js";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";

@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
export class PFormSelect extends Vue {
  @Prop({required: true}) value!: unknown;

  @Watch("value", {immediate: true, deep: true})
  onChangeValue(): void {
    if (this.valueAsArray) {
      this.selectedItemValue = (this.value as unknown[])?.length
        ? JSON.parse(JSON.stringify((this.value as unknown[])[0]))
        : null;
    } else {
      this.selectedItemValue = JSON.parse(JSON.stringify(this.value ?? null));
    }
  }

  @Prop({required: true}) options!: Record<string, unknown>[];
  @Prop({required: false, default: "value"}) optionValue!: string;
  @Prop({required: false, default: "text"}) optionText!: string;
  @Prop({required: false, default: ""}) label!: string;
  @Prop({required: false, default: undefined}) placeholder!: string;
  @Prop({required: false, default: "primary"}) variant!: "primary" | "button" | "withoutBox" | "round";
  @Prop({required: false, default: ""}) icon!: string;
  @Prop({required: false, default: ""}) iType!: string;
  @Prop({required: false, default: 16}) iSize!: number;
  @Prop({required: false, default: "#6f6f6f"}) iColor!: string;
  @Prop({required: false, default: undefined}) rules!: string;
  @Prop({required: false, default: ""}) invalid_feedback!: string;
  @Prop({required: false, default: false}) disabled!: boolean;
  @Prop({required: false, default: false}) valueAsArray!: boolean;
  @Prop({required: false, default: false}) minContainer!: boolean;
  @Prop({ required: false, default: "bottom-start" }) placement!: Placement;

  @Watch("pussOption", {immediate: true, deep: true})
  onChangePussOption(): void {
    if (!this.pussOption) {
      this.valueSearch = "";
    } else {
      this.$nextTick(() => {
        (this.$refs.inputSearch as HTMLElement).focus();
      });
    }
  }
  @Watch("valueSearch", { immediate: true, deep: true })
  onChangeSearch(): void {
    this.$emit("valueSearch", this.valueSearch);
  }
  selectedItemValue: unknown = null;
  pussOption = false;
  valueSearch = "";

  mounted(): void {
    this.$emit("input", this.selectedItemValue);
    this.$emit("change", this.selectedItemValue);
  }


  get optionsFilering(): any[] {
    return this.options.filter(
      (opt: any) =>
        opt[this.optionText].toLowerCase().indexOf(this.valueSearch.toLowerCase()) >= 0 ||
        opt[this.optionText]
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.valueSearch.toLowerCase()) >= 0
    );
  }

  get variantCop(): string {
    let returnTemp: string = "inputSelect_PFormSelect_" + this.variant;
    if (this.variant === "button" && this.selectedItemValue != "") {
      returnTemp = returnTemp + " borderSelected_PFormSelect";
    }
    return returnTemp;
  }

  get variantExpandCop(): string {
    return "expand_moreSelect_PFormSelect_" + this.variant;
  }

  get cursor(): string {
    return this.disabled ? "containerSelect_PFormSelect disabled" : "containerSelect_PFormSelect";
  }

  get selectedText(): unknown {
    const optionFind = this.options.find((opt) => opt[this.optionValue] == this.selectedItemValue);
    if (optionFind) {
      return optionFind[this.optionText];
    } else {
      return this.placeholder ??  this.$t('action.selectOption') as string;
    }
  }

  selectItem(itemValue: unknown): void {
    let val;
    if (this.valueAsArray) {
      val = itemValue !== null && itemValue !== undefined ? [itemValue] : [];
    } else {
      if(this.selectedItemValue == itemValue){
        this.selectedItemValue = "";
        val = "";
      }else {
        val = itemValue;
      }
    }

    this.selectedItemValue = JSON.parse(JSON.stringify(itemValue));
    this.$emit("input", val);
    this.$emit("change", val);

    this.pussOption = false;
  }

  giveMeClass(itemValue: unknown): string {
    if (this.selectedItemValue == itemValue) {
      return "itemSelectSelected_PFormSelect";
    } else {
      return "itemSelect_PFormSelect";
    }
  }

  open(): void {
    if (!this.disabled) {
      this.pussOption = !this.pussOption;
    }
  }
}

export default PFormSelect;
