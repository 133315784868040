import { Device, DeviceServicePoint, RawDeviceServicePoint } from "@/entities";
import { BACKEND_BASE_URL_READS, ResponseCreate } from "../backend.types";
import { DeviceService } from "@/services";
import Vue from "vue";
import { DeviceAlert } from "@/entities/deviceAlert/deviceAlert";
import { RawDeviceAlert } from "@/entities/deviceAlert/deviceAlert.types";

export class DeviceServiceImpl implements DeviceService {
  async fetchDeviceById(deviceId: string): Promise<DeviceServicePoint> {
    const { data } = await Vue.$axios.get<RawDeviceServicePoint>(`${BACKEND_BASE_URL_READS}/api/v1/device/${deviceId}`);
    return new DeviceServicePoint(data);
  }

  async fetchDeviceAlerts(deviceId: string): Promise<DeviceAlert[]> {
    const { data } = await Vue.$axios.get<RawDeviceAlert[]>(
      `${BACKEND_BASE_URL_READS}/api/v1/device/${deviceId}/alerts`
    );
    return data.map((rawDeviceAlert) => new DeviceAlert(rawDeviceAlert));
  }

  async createDevice(device: Device): Promise<ResponseCreate> {
    console.log("createDevice " + JSON.stringify(device));
    return { uuid: "" };
  }

  async updateDevice(device: Device): Promise<void> {
    console.log("updateDevice " + JSON.stringify(device));
  }

  async changeStatus(device: Device): Promise<void> {
    console.log("changeStatus " + JSON.stringify(device));
  }
}
