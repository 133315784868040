import { RawEntity, Limit } from "@/entities";

export class Entity {
  id: string;
  readonly name: string;
  readonly code: string;
  readonly icon: string;
  readonly imageLink: string;
  readonly center: number;
  readonly service_point: number;
  readonly machine: number;
  readonly user: number;
  readonly countryCode: string;
  active: boolean;
  limits: Limit[];

  constructor(data: RawEntity) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.code = data.code || "";
    this.icon = data.imageLink || "";
    this.imageLink = data.imageLink || "";
    this.center = data.centerCount || 0;
    this.service_point = data.servicePointCount || 0;
    this.machine = data.deviceCount || 0;
    this.user = data.customerCount || 0;
    this.countryCode = data.countryCode || "";
    this.active = data.active || false;
    this.limits = data.currencyLimits ? data.currencyLimits.map((value) => new Limit(value)) : [];
  }
}
