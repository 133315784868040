import Vue from "vue";
import Pageable from "@/entities/pageable/pageable_collection";
import { RawServicePoint, RawServicePointType, ServicePoint, ServicepointType } from "@/entities";
import { BACKEND_BASE_URL, BACKEND_BASE_URL_READS, ResponseCreate } from "../backend.types";
import { ServicePointService } from "@/services";
import { RawPage } from "@/entities/pageable/pageable_types";
import { DeviceServicePoint } from "@/entities/deviceservicepoint";

export class ServicePointServiceImpl implements ServicePointService {
  async fetchServicePoints(map: Record<string, unknown>): Promise<Pageable<ServicePoint>> {
    const { data } = await Vue.$axios.post<RawPage<RawServicePoint>>(
      `${BACKEND_BASE_URL_READS}/api/v2/servicepoints`,
      map
    );
    if (!data.results && data.servicepoints) {
      data.results = data.servicepoints;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawServicePoint) => new ServicePoint(rawServicePoint)) ?? [],
    };
  }

  async fetchServicePointsByGroupRules(groupId: string, map: Record<string, unknown>): Promise<Pageable<ServicePoint>> {
    const { data } = await Vue.$axios.post<RawPage<RawServicePoint>>(
      `${BACKEND_BASE_URL_READS}/api/v1/group/${groupId}/rules/servicepoints`,
      map
    );
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawServicePoint) => new ServicePoint(rawServicePoint)) ?? [],
    };
  }

  async createServicePoint(servicePoint: ServicePoint): Promise<ResponseCreate> {
    const { data } = await Vue.$axios.post<ResponseCreate>(`${BACKEND_BASE_URL}/api/v1/servicepoint`, {
      entityId: servicePoint.entityId,
      name: servicePoint.name,
      code: servicePoint.code,
      centerId: servicePoint.centerId,
      servicePointTypeId: servicePoint.servicePointType?.id,
      isActive: true,
      isEarlyValue: true,
      isMultiClient: true,
      currencyLimits: servicePoint.limits,
    });
    return data;
  }

  async createServicePointAndDevice(servicePoint: ServicePoint, device: DeviceServicePoint): Promise<ResponseCreate> {
    const { data } = await Vue.$axios.post<ResponseCreate>(`${BACKEND_BASE_URL}/api/v1/servicepoint-device`, {
      servicePoint: {
        entityId: servicePoint.entityId,
        centerId: servicePoint.centerId,
        name: servicePoint.name,
        code: servicePoint.code,
        servicePointTypeId: servicePoint.servicePointType?.id,
        isActive: true,
        isEarlyValue: true,
        isMultiClient: true,
        currencyLimits: servicePoint.limits,
      },
      device: {
        code: device.code,
        name: device.name,
        type: device.type,
        manufacturer: device.manufacturer,
        model: device.model,
        ip: device.ip,
        port: device.port,
        active: true,
        currencies: device.currencies,
        manufacturerNoteCapacity: device.manufacturerNoteCapacity || 0,
        realNoteCapacity: device.realNoteCapacity || 0,
        prosegurNoteCapacity: device.prosegurNoteCapacity || 0,
        manufacturerCoinCapacity: device.manufacturerCoinCapacity || 0,
        realCoinCapacity: device.realCoinCapacity || 0,
        prosegurCoinCapacity: device.prosegurCoinCapacity || 0,
        deviceAlerts: device.deviceAlerts,
      },
    });

    return data;
  }

  async updateServicePoint(servicePoint: ServicePoint): Promise<void> {
    console.log("updateServicePoint " + JSON.stringify(servicePoint));
    const { data } = await Vue.$axios.patch<any>(`${BACKEND_BASE_URL}/api/v1/servicepoint/${servicePoint.id}`, {
      name: servicePoint.name,
      servicePointTypeId: servicePoint.servicePointType?.id,
      isActive: true,
      isEarlyValue: true,
      isMultiClient: true,
      currencyLimits: servicePoint.limits,
    });
    return data;
  }

  async updateDeviceServicePoint(device: DeviceServicePoint): Promise<void> {
    console.log("updateServicePoint " + JSON.stringify(device));
    const { data } = await Vue.$axios.patch<any>(`${BACKEND_BASE_URL}/api/v1/device/${device.id}`, {
      name: device.name,
      type: device.type,
      manufacturer: device.manufacturer,
      model: device.model,
      ip: device.ip,
      port: device.port,
      active: true,
      currencies: device.currencies,
      manufacturerNoteCapacity: device.manufacturerNoteCapacity || 0,
      realNoteCapacity: device.realNoteCapacity || 0,
      prosegurNoteCapacity: device.prosegurNoteCapacity || 0,
      manufacturerCoinCapacity: device.manufacturerCoinCapacity || 0,
      realCoinCapacity: device.realCoinCapacity || 0,
      prosegurCoinCapacity: device.prosegurCoinCapacity || 0,
      deviceAlerts: device.deviceAlerts,
    });
    return data;
  }

  async changeStatus(servicePoint: ServicePoint): Promise<void> {
    await Vue.$axios.patch<void>(`${BACKEND_BASE_URL}/api/v1/servicepoint/${servicePoint.id}`, {
      isActive: servicePoint.active,
    });
  }
  async fetchServicePointTypes(): Promise<ServicepointType[]> {
    const { data } = await Vue.$axios.get<{ servicePointTypes: RawServicePointType[] }>(
      `${BACKEND_BASE_URL_READS}/api/v1/servicepointtypes`
    );
    return data.servicePointTypes.map((v) => new ServicepointType(v));
  }
}
