
import { Component, Prop, Vue } from "vue-property-decorator";
import { CashTodayFilter } from "@/components/groups/group-permissions-tab/cashtoday-filter/CashTodayFilter.vue";
import ManualRulesGrid from "@/components/user-detail/user-detail-machines-tab/manual-rules-grid/ManualRulesGrid.vue";
import ManualRulesGridAssign from "@/components/user-detail/user-detail-machines-tab/manual-rules-grid-assign/ManualRulesGridAssign.vue";
import { IServicePointFilter, iniServicePointFilter } from "@/services";
import { AssignedServicePoint } from "@/entities/assignedServicePoint";

@Component({
  name: "UserDetailMachinesTab",
  components: {
    ManualRulesGrid,
    ManualRulesGridAssign,
    CashTodayFilter,
  },
})
export class UserDetailMachinesTab extends Vue {
  @Prop({ required: true }) assignedServicePoints!: AssignedServicePoint[];
  @Prop({ required: true }) update!: boolean;

  filters: IServicePointFilter = { ...iniServicePointFilter };
  filtersAssign = { text: "" };

  refresh(): void {
    this.$emit("refresh");
  }
}
export default UserDetailMachinesTab;
