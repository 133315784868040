export type RawServicePointType = {
  readonly aggregateId: string;
  readonly id: string;
  readonly code: string;
  readonly name: string;
};

export const iniRawServicePointType: RawServicePointType = {
  aggregateId: "",
  code: "",
  name: "",
  id: "",
};
