
import { Component, Vue } from "vue-property-decorator";
import { Center, Entity, iniRawCenter, iniRawEntity } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import { Breadcrum } from "@/components/commons";
import { iniServicePointPageFilter, IServicePointPageFilter, SearchEntitiesRequest } from "@/services";
import ServicePointTabs from "@/components/servicePoints/servicePoint-tabs/ServicePointTabs.vue";
import ServicePointGrid from "@/components/servicePoints/servicePoint-grid/ServicePointGrid.vue";
import { ServicePointFilters, ServicePointHeader } from "@/components/servicePoints";
import LinkHidden from "@/components/commons/link-hidden/LinkHidden.vue";

@Component({
  components: {
    ServicePointHeader,
    Breadcrum,
    ServicePointTabs,
    ServicePointGrid,
    ServicePointFilters,
    LinkHidden,
  },
})
export class ServicePointPage extends Vue {
  filters: IServicePointPageFilter = { ...iniServicePointPageFilter };
  breadcrum: string[] = [this.$t("centerPage.breadcrum") as string];
  paths: string[] = ["/entities"];
  centerId!: string;
  entityId!: string;
  center: Center = new Center({ ...iniRawCenter });
  entity: Entity = new Entity({ ...iniRawEntity });
  loaded = false;
  countResults: { centers: number } = {
    centers: 0,
  };

  created(): void {
    this.entityId = this.$route.params.id;
    this.fetchEntity();
    this.centerId = this.$route.params.idCenter;
    this.paths.push("/entity/" + this.$route.params.id + "/centers/");
  }

  refresh(): void {
    this.filters.refresh = !this.filters.refresh;
  }

  fetchEntity(): void {
    const requestParams: SearchEntitiesRequest = {
      aggregateId: this.entityId,
      limit: 100,
      offset: 0,
      sortField: "aggregateId",
      sortWay: "DESC",
    };
    this.$services.entity
      .fetchEntitiesV2(requestParams)
      .then((response: Pageable<Entity>) => {
        if (response.results.length == 1) {
          this.entity = response.results[0];
          this.breadcrum.push(this.entity.name);
          this.fetchCenter();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchCenter(): void {
    const query: Record<string, unknown> = {};
    query["limit"] = 1;
    query["offset"] = 0;
    query["aggregateId"] = this.centerId;
    query["sortField"] = "centerName";
    query["sortWay"] = "ASC";
    this.$services.center
      .fetchCentersV2(query)
      .then((response: Pageable<Center>) => {
        if (response.results.length == 1) {
          this.center = response.results[0];
          this.breadcrum.push(this.center.name!);
          this.loaded = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default ServicePointPage;
