
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import EntityModal from "@/components/entities/entity-modal/EntityModal.vue";
import {
  Center,
  Device,
  Entity,
  iniRawCenter,
  iniRawDevice,
  iniRawEntity,
  iniRawServicePoint,
  ServicePoint,
} from "@/entities";
import CenterModal from "@/components/centers/center-modal/CenterModal.vue";
import ServicePointModal from "@/components/servicePoints/servicePoint-modal/ServicePointModal.vue";
import DeviceModalServicePoint from "../devices/device-modal/DeviceModal.vue";

@Component({
  name: "wizard",
  components: {
    EntityModal,
    CenterModal,
    ServicePointModal,
    DeviceModalServicePoint,
  },
})
export class Wizard extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.showWizard = this.value;
    this.localFase = this.fase;
    this.showModalEntity = this.showWizard && this.localFase == 1;
    this.showModalCenter = this.showWizard && this.localFase == 2;
    this.showModalServicePoint = this.showWizard && this.localFase == 3;
    this.showModalDevice = this.showWizard && this.localFase == 4;
    if (this.showModalEntity) {
      this.localEntity = new Entity({ ...iniRawEntity });
    }
    if (this.showModalCenter) {
      this.localCenter = new Center({ ...iniRawCenter });
    }
    if (this.showModalServicePoint) {
      this.localServicePoint = new ServicePoint({ ...iniRawServicePoint });
    }
    if (this.showModalDevice) {
      this.localDevice = new Device({ ...iniRawDevice });
    }
  }
  @Prop({ required: false, default: 0 }) fase!: number;
  @Watch("fase", { immediate: true, deep: true })
  onChangeFase(): void {
    this.localFase = this.fase;
  }
  @Prop({ required: false }) entity?: Entity;
  @Watch("entity", { immediate: true, deep: true })
  onChangeEntity(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  @Prop({ required: false }) center?: Center;
  @Watch("center", { immediate: true, deep: true })
  onChangeCenter(): void {
    if (this.center) {
      this.localCenter = { ...this.center };
    }
  }
  @Prop({ required: false }) servicePoint?: ServicePoint;
  @Watch("servicePoint", { immediate: true, deep: true })
  onChangeServicePoint(): void {
    if (this.servicePoint) {
      this.localServicePoint = { ...this.servicePoint };
    }
  }
  @Prop({ required: false }) device?: Device;
  @Watch("device", { immediate: true, deep: true })
  onChangeDevice(): void {
    if (this.device) {
      this.localDevice = { ...this.device };
    }
  }

  localEntity: Entity = new Entity({ ...iniRawEntity });
  localCenter: Center = new Center({ ...iniRawCenter });
  localServicePoint: ServicePoint = new ServicePoint({ ...iniRawServicePoint });
  localDevice: Device = new Device({ ...iniRawDevice });

  localFase = 0;
  showWizard = false;
  showModalEntity = false;
  @Watch("showModalEntity", { immediate: true, deep: true })
  onChangeShowModalEntity(): void {
    if (this.localFase == 1) {
      this.$emit("input", this.showModalEntity);
      if (this.fase == 1 && !this.showModalEntity) {
        this.localFase = this.fase;
      }
    } else {
      this.showModalCenter = this.showWizard && this.localFase == 2;
    }
  }
  showModalCenter = false;
  @Watch("showModalCenter", { immediate: true, deep: true })
  onChangeShowModalCenter(): void {
    if (this.localFase == 2) {
      this.$emit("input", this.showModalCenter);
      if (this.fase == 2 && !this.showModalCenter) {
        this.localFase = this.fase;
      }
    } else {
      this.showModalServicePoint = this.showWizard && this.localFase == 3;
    }
  }
  showModalServicePoint = false;
  @Watch("showModalServicePoint", { immediate: true, deep: true })
  onChangeShowModalServicePoint(): void {
    if (this.localFase == 3) {
      this.$emit("input", this.showModalServicePoint);
      if (this.fase == 3 && !this.showModalServicePoint) {
        this.localFase = this.fase;
      }
    } else {
      this.showModalDevice = this.showWizard && this.localFase == 4;
    }
  }

  showModalDevice = false;
  @Watch("showModalDevice", { immediate: true, deep: true })
  onChangeShowModalDevice(): void {
    if (this.localFase == 4) {
      this.$emit("input", this.showModalDevice);
      if (this.fase == 4 && !this.showModalDevice) {
        this.localFase = this.fase;
      }
    }
  }

  nextFase(response: Entity | Center | ServicePoint | Device): void {
    if (this.localFase == 1) {
      this.localEntity = response as Entity;
    } else if (this.localFase == 2) {
      this.localCenter = response as Center;
    } else if (this.localFase == 3) {
      this.localServicePoint = response as ServicePoint;
    } else if (this.localFase == 4) {
      this.localDevice = response as Device;
    }
    this.localFase++;
  }

  refresh(): void {
    if (this.localFase == this.fase || this.localFase === 4) {
      this.$emit("refresh");
    }
  }
}
export default Wizard;
