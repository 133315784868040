
import { Vue, Component, Prop } from "vue-property-decorator";
import { PButton, PModal, PFormInputTextSearch } from "@/common/components";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import MembersList from "./MembersList.vue";
import { i18n } from "@/i18n";
import { EventBus } from "@/event-bus";
import { SearchCustomerRequest } from "@/components";

@Component({
  name: "group-add-member",
  i18n,
  components: {
    PIcon,
    PButton,
    PModal,
    MembersList,
    PFormInputTextSearch,
  },
})
export default class GroupAddMember extends Vue {
  @Prop({ required: true }) groupId!: string;

  showModal = false;
  title = this.$i18n.t("groups.member");
  added = this.$i18n.t("groups.users_added");
  notSelected = this.$i18n.t("groups.no_users_selected");
  search = "";
  user_name = "";
  status = "Done";
  link = "link";
  usersList: Record<string, unknown>[] = [];
  usersToAddList: Record<string, unknown>[] = [];

  openModal(): void {
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
    this.reset();
  }

  reset(): void {
    this.usersList = [];
    this.user_name = "";
    this.usersToAddList = [];
    const obj: MembersList = this.$refs["pmemberlist"] as MembersList;
    obj.clearList();
  }

  setUsers(users: Record<string, unknown>[]): void {
    this.usersToAddList = users;
  }

  async associateCustomers(): Promise<void> {
    if (this.usersToAddList.length == 0) {
      Vue.swal({
        icon: "warning",
        title: this.notSelected,
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    } else {
      this.$emit("users", this.usersToAddList);
      EventBus.$emit("search-users", this.usersToAddList);
      this.showModal = false;
      this.user_name = "";
      this.reset();
    }
  }

  async searchUser(): Promise<void> {
    if (this.user_name.length > 2) {
      const request: SearchCustomerRequest = {
        fullNameOrEmail: this.user_name,
        active: true,
        limit: 100,
        offset: 0,
        sortField: "fullName",
        sortWay: "asc",
      };

      const data = await this.$services.user.fetchUsersV2(request);
      this.usersList = [];
      const filteredUsers = data.results?.filter((user) => user.group?.id !== this.groupId || user.group);
      filteredUsers?.forEach((item) =>
        this.usersList.push({
          avatarLetter: item.avatarLetter,
          name: item.name,
          lastName: item.lastName,
          id: item.id,
          email: item.email,
          fullName: item.fullName,
          country: item.country,
          active: item.active,
          newUser: true,
        })
      );
    } else {
      this.usersList = [];
    }
  }
}
