
import { Center, Entity, iniRawServicePoint, ServicePoint } from "@/entities";
import { i18n } from "@/i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import PIcon from "@/common/components/look/PIcon/PIcon.vue";
import { PButton, PModal } from "@/common/components";
import ServicePointForm from "@/components/servicePoints/servicePoint-form/ServicePointForm.vue";
import Wizard from "@/components/commons/Wizard.vue";

@Component({
  name: "center-header",
  i18n,
  components: {
    PModal,
    PIcon,
    PButton,
    ServicePointForm,
    Wizard,
  },
})
export class ServicePointHeader extends Vue {
  @Prop({ required: true }) center!: Center;
  @Prop({ required: true }) entity!: Entity;
  showModal = false;
  errorModalMessage = "";
  servicePoint: ServicePoint = new ServicePoint({ ...iniRawServicePoint });

  openModalCreate(): void {
    this.servicePoint = new ServicePoint({ ...iniRawServicePoint });
    this.showModal = true;
  }

  refresh(): void {
    this.$emit("refresh");
  }
}

export default ServicePointHeader;
