import { Entity } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import { ResponseCreate } from "@/services";

export interface EntityService {
  fetchEntities(map: Record<string, unknown>): Promise<Pageable<Entity>>;
  fetchEntitiesV2(request: SearchEntitiesRequest): Promise<Pageable<Entity>>;
  updateEntity(entity: Entity): Promise<void>;
  changeStatus(entity: Entity): Promise<void>;
  createEntity(entity: Entity): Promise<ResponseCreate>;
}

export interface EntityServiceMock {
  fetchEntities: jest.Mock<Promise<Pageable<Entity>>>;
  fetchEntitiesV2: jest.Mock<Promise<Pageable<Entity>>>;
  updateEntity: jest.Mock<Promise<void>>;
  changeStatus: jest.Mock<Promise<void>>;
  createEntity: jest.Mock<Promise<ResponseCreate>>;
}

export interface IEntityFilter {
  code?: string;
  name?: string;
  country?: string;
  active?: boolean;
  codeOrName?: string;
  refresh: boolean;
}

export const iniEntityFilter: IEntityFilter = {
  code: "",
  name: "",
  country: "",
  codeOrName: "",
  active: true,
  refresh: false,
};

export interface SearchEntitiesRequest {
  codeOrName?: string;
  countryCode?: string;
  aggregateId?: string;
  limit: number;
  offset: number;
  sortField: string;
  sortWay: string;
  active?: boolean;
  withCounters?: boolean;
}
